import React from 'react';

function NoSelection({ handleLinkClick }) {
 return (
  <div className='noSelection'>
   <p>Welcome the World Literature Knowledge Graph, a collection of 194,269 writers and their works gathered from Wikidata, Goodreads, Google Books and Open Library.</p>
   <p> To start using this resource, you can search for an
    <a draggable
     onClick={handleLinkClick}
     href='https://purl.archive.org/urwriters#Person'>
     <span className='a_imagelink author'>author</span>
    </a>
    , a
    <a draggable
     onClick={handleLinkClick}
     href='https://purl.archive.org/urbooks#Expression'>
     <span className='a_imagelink work'>work</span>
    </a>
    or a <a draggable
     onClick={handleLinkClick}
     href='https://purl.archive.org/urbooks#Place'>
     <span className='a_imagelink place'>place</span>
    </a>
    in the left box. </p>
   <p>Word Literature Knowledge Graph is also able to highlight the authors' belonging to a <a draggable
    onClick={handleLinkClick}
    href='https://purl.archive.org/urwriters#Minority'>
    <span className='a_imagelink minority'>minority</span>
   </a>.</p>

   <p>Once you find the entity you are looking for, drag and drop it in the central part of the page.</p>
   <p>If you double-click on the entity, an info-box will appear on the right, with biographical and/or publishing information.</p>

  </div>
 );
}

export default NoSelection;
