import React from "react";
import Button from '@material-ui/core/Button';

function Step1(props) {
  return (
    <div>
      <div className='instr'>Inserisci l'email di due amici. Il team di Arca creerà un account per ogni persona e invierà loro la password di accesso.</div>
      <div>
        <div className='mailIn'>
            Prima email: <input name="email1" onChange={props.handleChange} />
        </div>
        <div className='mailIn'>
            Seconda email: <input name="email2" onChange={props.handleChange} />
        </div>
      </div>
      <div>
          <Button onClick={props.next}>
          INVIA
          </Button>
      </div>
    </div>
  );
}

export default Step1;
