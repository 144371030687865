import React, { useEffect } from 'react';
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import '../../styles/questionario.css';
import Draggable from './DraggablePath';
import MapIcon from '@material-ui/icons/Map';
import { useVenti } from 'venti';
import { State, state } from 'venti';
const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

// const DialogTitle = withStyles(styles)((props) => {
// 	const { children, classes, onClose, ...other } = props;
// 	return (
// 		<MuiDialogTitle disableTypography className={classes.root} {...other}>
// 			<Typography variant="h6">{children}</Typography>
// 			{onClose ? (
// 				<IconButton
// 					aria-label="close"
// 					className={classes.closeButton}
// 					onClick={onClose}
// 				>
// 					<CloseIcon />
// 				</IconButton>
// 			) : null}
// 		</MuiDialogTitle>
// 	);
// });

// const DialogContent = withStyles((theme) => ({
// 	root: {
// 		padding: theme.spacing(2),
// 	},
// }))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
// 	root: {
// 		margin: 0,
// 		padding: theme.spacing(1),
// 	},
// }))(MuiDialogActions);

function TraceDrag(props) {

	const {	target } = props;
	
	const [open, setOpen] = React.useState(false);
	const [currentIri, setCurrentIri] = React.useState('');
	const [currentLabel, setCurrentLabel] = React.useState('');

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
		//call VENTI api
		state.set('eventsButton',{
			type: 'pathButton',
			timeStamp: new Date().toLocaleString(),
		})
	  };

	const LightTooltipInfo = withStyles((theme) => ({
		tooltip: {
			backgroundColor: theme.palette.common.white,
			color: 'rgba(0, 0, 0, 0.87)',
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	}))(Tooltip);

	//IS THE IMAGE BROKEN?
	var images = document.querySelectorAll('img');

	var errorHandler = function () {
		this.src = 'http://195.231.2.149/erma_assets/Copertine/def_cop.jpg';
	};

	for (var i = 0; i < images.length; i++) {
		images[i].onerror = errorHandler;
	}

	useEffect(() => {
        if (target !== undefined && currentIri != target.iri) {

			try {
				// const StrLabel = JSON.stringify(target._data.label.values.filter( (key)=>key.datatype==='http://www.w3.org/2001/XMLSchema#string' ? key.text : '' ));	ù
				const Label_array = target._data.label.values.filter( (key)=> key.datatype==='http://www.w3.org/2001/XMLSchema#string' ? key.text : '')
        		const Label = Label_array[0]['text']
				// myAnalytics.logEvent('SelectedConcept', { Iri: StrUri , Label: StrLabel});	
				setCurrentIri(target.iri);
				setCurrentLabel(Label);
			
			}
			catch {
				const StrLabel = 'target.iri'
				// myAnalytics.logEvent('SelectedConcept', { Iri: StrUri , Label: StrLabel});
				setCurrentIri(target.iri);
				setCurrentLabel(StrLabel);
			}	
        }
      }, [target]);

	return (
		<div>
			<LightTooltipInfo enterDelay={500} title="Trace Path tool">
				<Button
					type="button"
					fullWidth
					variant="contained"
					color="primary"
					disableElevation
					className="button_questionnaire"
					onClick={handleToggle}
				>
					{open ? <span>HIDE<MapIcon/></span> : <MapIcon/>}
					
				</Button>
			</LightTooltipInfo>

			<div className={open ? 'home_form_visible' : 'home_form_hidden'}>
				<Draggable Iri = {currentIri} Label = {currentLabel}/>
			</div>
		</div>
	);
}


function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		isVerifying: state.auth.isVerifying,
		watermarkSvg: state.graph.watermarkSvg,
		watermarkUrl: state.graph.watermarkUrl,
		criteria: state.graph.criteria,
		target: state.graph.target,
	};
  }
  
  function mapDispatchToProps(dispatch) {
	  return {
		  onSearchCriteriaChanged: (value) => dispatch({
			  type: 'UPDATECRITERIA',
			  criteria: value
		  }),
	  };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps, null, {
	  forwardRef: true
  })(TraceDrag)