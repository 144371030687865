import React, { useEffect, } from 'react';
import { Container, Row, Col, Card} from "react-bootstrap";
import { useState } from 'react';
import request from 'superagent';
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import $ from 'jquery';
import MoreIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
const Validation = ({ setOpen, selectedConcept, selectedBook, ...props }) => {

    const { user } = props;

    // console.log(user)

    const [resource, setResource] = useState(selectedConcept)
    const [book, setBook] = useState(selectedBook)
    const [relationName, setRelationName] = useState('')
    const [relationType, setRelationType] = useState('')
    const [relationConfirmed, setRelationConfirmed] = useState('')
    const [relationDeleted, setRelationDeleted] = useState('')
    const [settingsOpen, setSettingsOpen] = useState('')
    const [modOn, setModOn] = useState(false)

    const [userID, setUserID] = useState(user.email)

    const handleSetting = () => {
        setSettingsOpen(!settingsOpen);
    };

    const openMod = () => {
        setModOn(true);
    };

    const LightTooltipInfo = withStyles(theme => ({
        tooltip: {
          backgroundColor: theme.palette.common.black,
          color: '#fff',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }))(Tooltip);

    // find concept/top_concept | confirmed_concept/confirmed_top_concept
    useEffect(() => {
        findRelationName()
    });

    useEffect(() => {
        const userIDMOD = userID.replace('@', 'AT').replace('.', 'DOT').replace('_', '-')
        setUserID(userIDMOD)
    }, []);

    useEffect(() => {
        if (("string " + relationName).toString().includes('top_concept')) {
            setRelationType('top_concept')
            setRelationConfirmed('http://lerma.org/confirmed_top_concept')
            setRelationDeleted('http://lerma.org/deleted_top_concept')
        }
        else {
            setRelationType('concept')
            setRelationConfirmed('http://lerma.org/confirmed_concept')
            setRelationDeleted('http://lerma.org/deleted_concept')
        }
        // console.log(relationName,relationConfirmed)

    }, [relationName]);

    function findRelationName() {
        request
            .get('/sparql-endpoint')
            .accept('application/json')

            .query({
                query:
                    ` 
            SELECT ?relationship
            WHERE {<` + book + `> ?relationship <` + resource + `> }  
          `
            })

            .buffer(true)

            .end((err, resp) => {
                if (!err) {
                    var output = JSON.parse(resp.text).results.bindings

                    let listRelation = output.map((item) => {
                        return (
                            item.relationship.value
                        );
                    });
                    // console.log(listRelation[0])
                    setRelationName(listRelation[0])
                }
            }
            )
        // console.log(result)
    }

    function deleteExistent() {
        const queryWhat = `
        PREFIX lerma:     <http://lerma.org/>
        
        DELETE DATA {
            {
                <` + book + `> <` + relationName + `> <` + resource + `> .
            }
        }
        `
        request
            .post(`/sparql-endpoint`)
            .send(queryWhat)
            .set("Accept", "*/*")
            .set("Content-Type", "application/sparql-update")
            .end((err, res) => {
                if (err) {
                    console.log(err);
                }
            });
    }

    // //  insert confirmed relation
    function insertConfirmed() {
        const queryWhat = `
        PREFIX lerma:    <http://lerma.org/> 

        INSERT DATA {
            {
                <` + book + `> <` + relationConfirmed + `> <` + resource + `> .
            }
        }
        `;
        request
            .post(`/sparql-endpoint`)
            .send(queryWhat)
            .set("Accept", "*/*")
            .set("Content-Type", "application/sparql-update")
            .end((err, res) => {
                if (err) {
                    console.log(err);
                }
            });
    }

    // //  insert deleted relation
    function insertDeleted() {
        const queryWhat = `
        PREFIX lerma:    <http://lerma.org/> 

        INSERT DATA {
            {
                <` + book + `> <` + relationDeleted + `> <` + resource + `> .
            }
        }
        `;
        request
            .post(`/sparql-endpoint`)
            .send(queryWhat)
            .set("Accept", "*/*")
            .set("Content-Type", "application/sparql-update")
            .end((err, res) => {
                if (err) {
                    console.log(err);
                }
            });
    }

    // // insert activity
    function insertActivity(typeActivity) {
        const queryWhat = `
            PREFIX idActivity:     <http://lerma.org/idActivity/>
            PREFIX activity:       <http://lerma.org/activity/>
            PREFIX idUser:         <http://lerma.org/idUser/>
            PREFIX lermabook:      <http://www.lerma.it/index.php?pg=SchedaTitolo&key=> 
            PREFIX dc:             <http://purl.org/dc/elements/1.1/> 

            INSERT DATA {
                { 
                    idActivity:` + userID + '-activity-' + Date.now() + ` a activity:validation ;
                    dc:date ` + Date.now() + `;
                    dc:type "StatementTriple" ;

                    activity:predicate 
                        <` + relationName + `>;
                        
                    activity:actionType 
                        activity:` + typeActivity + `;
                    
                    activity:user
                        idUser:` + userID + `;

                    activity:subject
                        <` + book + `>;
                
                    activity:object
                        <` + resource + `> ;
                }
            }`

        request
            .post(`/relation-validation`)
            .send(queryWhat)
            .set("Accept", "*/*")
            .set("Content-Type", "application/sparql-update")
            .end((err, res) => {
                if (err) {
                    console.log(err);
                }
            });
    }

    function confirmRelation() {
        deleteExistent();
        insertConfirmed();
        insertActivity('confirm');
        // console.log('new activity delete and insert', relationName)
        findRelationName();
        deleteExistent();
        insertConfirmed();
        findRelationName();
        setOpen(false);
    }

    function deleteRelation(e) {
        deleteExistent();
        insertDeleted();
        insertActivity('deny');
        findRelationName();
        deleteExistent();
        insertDeleted();
        findRelationName();
        setOpen(false);

    }

    return (
        <Container>
            <Row className="justify-content-md-center text-center">
                <Col xs={12} md={12} >
                    <span style={{ fontStyle: 'italic', padding: "2px", color: 'grey' }}>
                        {
                            ("string " + relationName).toString().includes('confirmed') ?
                                <><span style={{paddingRight:"15px"}}><CheckCircleIcon className='ConfirmIconInBox'/></span><strong>Confirmed Concept</strong></>
                                : <></>
                        }
                        {
                            ("string " + relationName).toString().includes('deleted') ?
                            <><span style={{paddingRight:"15px"}}><CancelIcon className='CancelIconBox'/></span><strong>Deleted Concept</strong></>
                                : <></>
                        }
                        {
                            ("string " + relationName).toString().includes('deleted') == false && ("string " + relationName).toString().includes('confirmed') == false ?
                                <><strong>Automatic extracted {relationType}. </strong>Validate the relationship</>
                                : <></>
                        }
                    </span>
                    {
                        (("string " + relationName).toString()).includes('deleted') || (("string " + relationName).toString()).includes('confirmed') ?
                            <span style={{ margin: "10px", verticalAlign: "sub" }}><LightTooltipInfo enterDelay={100} title="modify association"><MoreIcon style={{ color: 'black' }} onClick={handleSetting} /></LightTooltipInfo></span>
                            :
                            <></>
                    }
                </Col>
            </Row>
            { settingsOpen && ("string " + relationName).toString().includes('confirmed') ?
                <Row className="justify-content-md-center text-center">
                    <Button fullWidth
                        style={{margin:"10px",padding:"5px",width:"90px!important",height:"40px !important"}}
                        variant="contained"
                        color="primary"
                        disableElevation="true"
                        onClick={deleteRelation}>
                        Deny association
                    </Button>
                    <Button fullWidth
                        style={{margin:"10px",padding:"5px",width:"90px!important",height:"40px !important"}}
                        variant="contained"
                        color="primary"
                        disableElevation="true"
                        onClick={handleSetting}>
                        Cancel
                    </Button>
                </Row>
                : 
                settingsOpen && ("string " + relationName).toString().includes('deleted') ?
                <Row className="justify-content-md-center text-center">
                    <Button type="button"
                        style={{margin:"10px",padding:"5px",width:"90px!important",height:"40px !important"}}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disableElevation="true"
                        onClick={confirmRelation} >
                        Confirm association
                    </Button>
                
                    <Button fullWidth
                        style={{margin:"10px",padding:"5px",width:"90px!important",height:"40px !important"}}
                        variant="contained"
                        color="primary"
                        disableElevation="true"
                        onClick={handleSetting}>
                        Cancel
                    </Button>
                </Row>
                : 
                <></>
            }

            {/* {modOn ?
                <>
                    <Row className="justify-content-md-center  text-center">
                        <Col xs={12} md={12}>
                            <strong>Is the concept coherent with the tematic of book ?</strong>
                        </Col>
                        <Row className="justify-content-md-center text-center">
                            <Button onClick={confirmRelation} autoFocus color="primary">
                                Yes
                            </Button>
                            <Button onClick={deleteRelation} autoFocus color="primary">
                                No
                            </Button>
                        </Row>
                    </Row>
                </> :
                <></>
            } */}

            {
                ("string " + relationName).toString().includes('deleted') == false && ("string " + relationName).toString().includes('confirmed') == false ?
                    <Row className="justify-content-md-center text-center">
                        <Button type="button"
                            style={{margin:"10px",padding:"5px", width:"90px!important", height:"40px !important"}}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disableElevation="true"
                            onClick={confirmRelation} >
                            Confirm association
                        </Button>
                        <Button fullWidth
                            style={{margin:"10px",padding:"5px", width:"90px!important", height:"40px !important"}}
                            variant="contained"
                            color="primary"
                            disableElevation="true"
                            onClick={deleteRelation}>
                            Deny association
                        </Button>
                      
                    </Row>

                    // <Row className="justify-content-md-center  text-center">
                    //     <Col xs={12} md={12}>
                    //         <strong>Is the concept coherent with the tematic of book ?</strong>
                    //     </Col>
                    //     <Row className="justify-content-md-center text-center">
                    //         <Button onClick={confirmRelation} autoFocus color="primary">
                    //             Yes
                    //         </Button>
                    //         <Button onClick={deleteRelation} autoFocus color="primary">
                    //             No
                    //         </Button>
                    //     </Row>
                    // </Row>
                    :
                    <></>
            }
        </Container>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(Validation);