import React, { useState, useEffect, useCallback } from 'react';

import request from 'superagent'

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Container, Row, Col, Card } from "react-bootstrap";
import InfoIcon from '@material-ui/icons/Info';

import BuildIcon from '@material-ui/icons/More';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Grid from '@material-ui/core/Grid';

import Validation from './validation/index';
import ExpansionPanel from './Snippet/ExpansionPanel';

import "../../styles/Dialog.css";
import "../../styles/call_sparql.css";


//qui il link del libro ce l'ho però mi devo anche importare il link della risorsa selezionata!

const LightTooltipInfo = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const CustomizedDialogs = ({ update, setUpdate, confirmed, deleted, SelectedConcept, Link, Image, Title, Description, Year, Authors, Curators, LinkChronology, Chronology, LinkTopic, Topic, LinkTypology, Typology, ...props }) => {
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState(false);
  const [authorLast_confirmed, setAuthorLast_confirmed] = useState(false)

  function callLastAuthorConfirmed() {
    //LISTA ORDINA DATA CRESCENTE 
    request
      .get('/relation-validation')
      .accept('application/json')

      .query({
        query:
          `
     PREFIX activity:       <http://lerma.org/activity/>
     PREFIX idUser:         <http://lerma.org/idUser/>
     PREFIX lermabook:      <http://www.lerma.it/index.php?pg=SchedaTitolo&key=> 
     PREFIX dc:             <http://purl.org/dc/elements/1.1/> 
     
     SELECT ?idActivity ?subject ?object ?predicate ?date
     WHERE {
       {
         SELECT DISTINCT ?idActivity  WHERE {
     
           { ?idActivity 
             activity:actionType activity:confirm; 
             activity:object <` + SelectedConcept + `>;
             activity:subject <` + Link + `> .
     
            FILTER(isIri(?idActivity)) } 
     
     
           BIND ( str( ?idActivity ) as ?uriStr)
     
         }
     
                         LIMIT 1 OFFSET 0
       }
       OPTIONAL {?idActivity dc:date ?date}
       OPTIONAL {?idActivity activity:predicate ?predicate}
       OPTIONAL {?idActivity activity:subject ?subject}
       OPTIONAL {?idActivity activity:object ?object}        
     }
     ORDER BY DESC(?date)
     `
      })

      .buffer(true)

      .end((err, resp) => {
        if (!err) {
          var result = JSON.parse(resp.text).results.bindings
          console.log(result, Link, SelectedConcept)
          //  setAuthorLast_confirmed(result)
          setAuthorLast_confirmed(result.map((rowdata, i) => rowdata.idActivity.value))
        }
      }
      )
  }

  useEffect(() => {
    setUpdate(!update)
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // console.log(SelectedConcept, Link) // lista di tutti i link dei libri associati all'entità selezionata

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  //IS THE IMAGE BROKEN?
  var images = document.querySelectorAll('img');

  var errorHandler = function () {
    setSrc('http://195.231.2.149/erma_assets/Copertine/def_cop.jpg')
  }

  for (var i = 0; i < images.length; i++) {
    images[i].onerror = errorHandler
  }

  return (
    <div>

      {confirmed ?
        <div style={{ display: "inline-flex" }}>
          <span style={{ position: "relative", right: "108px" }}>
            <LightTooltipInfo enterDelay={500} title={authorLast_confirmed ? "confirmed by " + String(authorLast_confirmed).replace("http://lerma.org/idActivity/", "").replace("DOT", ".").replace("AT", "@").split("-activity-")[0] : "confirmed by ... click to see"} onClick={callLastAuthorConfirmed}>
              <CheckCircleIcon className='ConfirmIcon' />
            </LightTooltipInfo>
          </span>
          <span style={{ paddingLeft: "15px" }}>
            <LightTooltipInfo enterDelay={500} title="Info">
              <InfoIcon className='MoreIcon' onClick={handleClickOpen} />
            </LightTooltipInfo>
          </span>
        </div>
        :
        deleted ?
          <div style={{ display: "inline-flex" }}>
            <span style={{ position: "relative", right: "108px" }}>
              <LightTooltipInfo enterDelay={500} title="Denied association">
                <CancelIcon className='CancelIcon' />
              </LightTooltipInfo>
            </span>
            <span style={{ paddingLeft: "15px" }}>
              <LightTooltipInfo enterDelay={500} title="Denied association. Go to Info">
                <InfoIcon className='MoreIcon' onClick={handleClickOpen} />
              </LightTooltipInfo>
            </span>
          </div>
          :
          <>
            <LightTooltipInfo enterDelay={500} title="Info">
              <InfoIcon className='MoreIcon' onClick={handleClickOpen} />
            </LightTooltipInfo>
          </>

      }

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" >
          {Title}
        </DialogTitle>
        <DialogContent className="dialogContentDescrBook" dividers>

          <Grid
            container
            direction="row-reverse"
            justify="center"
            alignItems="stretch">

            <Grid className="GridDescriptionBook" item xs={12} sm={6}>
              <Typography className="descriptionBook" gutterBottom>
                {/* <p><strong>SelectedConcept: </strong>{SelectedConcept}</p>
                        <p><strong>Book: </strong>{Link}</p> */}
                <p><strong>Year of publication: </strong>{Year}</p>
                <p><strong>Authors: </strong>{Authors}</p>
                <p><strong>Curators: </strong>{Curators}</p>
                <p><strong>Chronology: </strong>{Chronology}</p>
                <p><strong>Topic: </strong>{Topic}</p>
                <p><strong>Typology: </strong>{Typology}</p>
                <p><strong>Description: </strong>{Description}</p>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="div_img_book">
                <LightTooltip enterDelay={400} title="Go to the book site">
                  <a href={Link} target="_blank">
                    <img className="img_book" onError={(e) => { e.target.onerror = null; e.target.src = "http://195.231.2.149/erma_assets/Copertine/def_cop.jpg" }} src={Image} target="_blank" href={Link} />
                  </a>
                </LightTooltip >
              </div>
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
          <ExpansionPanel SelectedConcept={SelectedConcept} Link={Link} Title={Title} />
        </DialogActions>
        <DialogActions center>
          <Validation
            selectedConcept={SelectedConcept}
            selectedBook={Link}
            setOpen={setOpen}
          />
        </DialogActions>


      </Dialog>

    </div>
  );
}

export default CustomizedDialogs;