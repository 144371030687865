import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
// import GeoQuery from './query';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useEffect } from 'react';
import { State, state } from 'venti';
import MapView from './mapView';

const styles = (theme) => ({
 root: {
  margin: 0,
  padding: theme.spacing(2),
 },
 closeButton: {
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
 },
});

const DialogTitle = withStyles(styles)((props) => {
 const { children, classes, onClose, ...other } = props;
 return (
  <MuiDialogTitle disableTypography className={classes.root} {...other}>
   <Typography variant="h6">{children}</Typography>
   {onClose ? (
    <IconButton
     aria-label="close"
     className={classes.closeButton}
     onClick={onClose}
    >
     <CloseIcon />
    </IconButton>
   ) : null}
  </MuiDialogTitle>
 );
});

const DialogContent = withStyles((theme) => ({
 root: {
  padding: theme.spacing(2),
 },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
 root: {
  margin: 0,
  padding: theme.spacing(1),
 },
}))(MuiDialogActions);

export default function MapDialog({ label, targetIri }) {
 const [open, setOpen] = React.useState(false);

 const handleLinkClick = (event) => {
  event.preventDefault();
 };

 const handleClickOpen = () => {
  setOpen(true);
  state.set('eventsButton', {
   type: 'legendButton',
   timeStamp: new Date().toLocaleString(),
  })
 };
 const handleClose = () => {
  setOpen(false);
 };

 const LightTooltipInfo = withStyles((theme) => ({
  tooltip: {
   backgroundColor: theme.palette.common.white,
   color: 'rgba(0, 0, 0, 0.87)',
   boxShadow: theme.shadows[1],
   fontSize: 11,
  },
 }))(Tooltip);

 return (
  <>
   <LightTooltipInfo
    enterDelay={500}
    title="View places"
    placement="right-start">
    <Button
     type="button"
     style={{
      position: 'absolute',
      right: '5px',
      top: '4px',
      outline: 'double',
      background: '#005d35!important',
      color: 'white',
      fontWeight: 'bolder'
     }}
     fullWidth
     variant="contained"
     color="primary"
     disableElevation
     className="button_tutorial"
     onClick={handleClickOpen}
    >
     MAP
    </Button>
   </LightTooltipInfo>

   <Dialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open={open}

   >
    <DialogTitle id="customized-dialog-title">Places of edition of the works of <a onClick={handleLinkClick} href={targetIri}><span style={{ fontWeight: 'bolder' }}>{label}</span></a> works </DialogTitle>

    <DialogContent style={{ textAlign: '-webkit-center', padding: '0px' }} dividers>
     <MapView targetIri={targetIri} />
    </DialogContent>
    <DialogActions>
     <Button autoFocus onClick={handleClose} color="primary">
      Close
     </Button>
    </DialogActions>
   </Dialog>
  </>
 );
}
