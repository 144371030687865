import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TutorialCustomizedDialogs from './Dialog_tutorial';
import LegendCustomizedDialogs from './Dialog_legend';
import Tooltip from '@material-ui/core/Tooltip';
import '../../styles/menu_button.css';


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.black,
		color: '#fff',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);

export default function FullWidthGrid() {
	const classes = useStyles();

	return (
		<div className='menu_button'>
			<Grid container  direction="column"  justify="center"  alignItems="center" container spacing={1}>
				<Grid item xs={1} sm={3}>
					<TutorialCustomizedDialogs />
				</Grid>

				{/* <Grid item xs={1} sm={3}>
					<LegendCustomizedDialogs />
				</Grid> */}
			</Grid>
		</div>
	);
}
