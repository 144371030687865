import React from 'react';
import '../styles/graph.css';
import { connect } from 'react-redux';
import { state } from 'venti';

var Ontodia = require('ontodia-wlkg');

function onWorkspaceMounted(workspace) {
	if (!workspace) {
		return;
	}
	const model = workspace.getModel();
	model.importLayout({
		diagram: Ontodia.makeSerializedDiagram({
			linkTypeOptions: [
				{
					"@type": "LinkTypeOptions",
					property: "http://dbpedia.org/ontology/wikiPageWikiLink",
					visible: false
				},
				{
					"@type": "LinkTypeOptions",
					property: "http://dbpedia.org/ontology/associate",
					visible: false
				},
				{
					"@type": "LinkTypeOptions",
					property: "http://www.w3.org/2000/01/rdf-schema#seeAlso",
					visible: false
				},
				{
					"@type": "LinkTypeOptions",
					property: "http://dbpedia.org/ontology/wikiPageEternalLink",
					visible: false
				}
			]
		}),
		validateLinks: true,
		dataProvider: new Ontodia.CompositeDataProvider(
			[
				new Ontodia.SparqlDataProvider(
					{
						name: 'DBpedia',
						endpointUrl: '/dbpedia',
						imagePropertyUris: [
							'http://xmlns.com/foaf/0.1/depiction',
							'http://xmlns.com/foaf/0.1/img',
						],
						queryMethod: Ontodia.SparqlQueryMethod.GET,
					},
					Ontodia.DBPediaSettings,
					{
						...Ontodia.DBPediaSettings,
						...{
							fullTextSearch: {
								prefix: 'PREFIX dbo: <http://dbpedia.org/ontology/>\n',
								queryPattern: `
									?inst rdfs:label ?searchLabel. 
									?searchLabel bif:contains "\${text}".
									?inst dbo:wikiPageID ?origScore .
									BIND(0-?origScore as ?score)
								`,
							},

							classTreeQuery: `
								SELECT distinct ?class ?label ?parent WHERE {
								?class rdfs:label ?label.
								OPTIONAL {?class rdfs:subClassOf ?parent}
								?root rdfs:subClassOf owl:Thing.
								?class rdfs:subClassOf? | rdfs:subClassOf/rdfs:subClassOf ?root
								}

							`,

							elementInfoQuery: `
								CONSTRUCT {
									?inst rdf:type ?class .
									?inst rdfs:label ?label .
									?inst ?propType ?propValue.
								} WHERE {
									VALUES (?inst) {\${ids}}
									?inst rdf:type ?class .
									?inst rdfs:label ?label .
									FILTER (lang(?propValue) = "" || langMatches(lang(?propValue), "IT")) .
									FILTER (!contains(str(?class), 'http://dbpedia.org/class/yago'))
									OPTIONAL {?inst ?propType ?propValue.
									FILTER (isLiteral(?propValue)) }
								}
								LIMIT 10

							`,

							filterElementInfoPattern: `
								OPTIONAL {?inst rdf:type ?foundClass. FILTER (!contains(str(?foundClass), 'http://dbpedia.org/class/yago'))}
								BIND (coalesce(?foundClass, owl:Thing) as ?class)
								OPTIONAL {?inst \${dataLabelProperty} ?label}
							`,

							imageQueryPattern: ` { ?inst ?linkType ?fullImage } UNION { [] ?linkType ?inst. BIND(?inst as ?fullImage) }
									BIND(CONCAT("https://commons.wikimedia.org/w/thumb.php?f=",
									STRAFTER(STR(?fullImage), "Special:FilePath/"), "&w=200") AS ?image)
							`,
						},
					}
				),
				new Ontodia.SparqlDataProvider(
                    {   
                        name: 'Goodreads',
                        endpointUrl: '/goodreads',
                        imagePropertyUris: [
                            "http://xmlns.com/foaf/0.1/depiction",
                            'http://xmlns.com/foaf/0.1/img',
                            "https://purl.archive.org/urwriters#image"
                        ],
                    },
                    {
                        ...Ontodia.OWLStatsSettings,
                        fullTextSearch: {
                            prefix:
                                'PREFIX skos: <http://www.w3.org/2004/02/skos/core#>\n' +
                                'PREFIX :<https://purl.archive.org/urwriters#>\n' +
                                'PREFIX urb:<https://purl.archive.org/urbooks#>\n',
                            queryPattern: `
                                SELECT ?inst ?label WHERE {
                                    ?inst rdfs:label ?label .
                                    FILTER (regex(?label, "\${text}", "i"))
                                }
								LIMIT 100
                                `,
                        },
                        elementInfoQuery: `
                            CONSTRUCT {
                                ?inst rdf:type ?class;
                                    rdfs:label ?label;
                                    ?propType ?propValue.
                            }
                            WHERE {
                                OPTIONAL {?inst rdf:type ?class . }
                                OPTIONAL {?inst \${dataLabelProperty} ?label}
                                OPTIONAL {?inst ?propType ?propValue.
                                    FILTER (isLiteral(?propValue)) }
                                VALUES ?labelProp { rdfs:label foaf:name }
                                FILTER (?inst = \${ids})
                            }
                          `,
                    }
                ),
								
				new Ontodia.SparqlDataProvider(
                    {
                        name: 'Olid',
                        endpointUrl: '/olid',
                        imagePropertyUris: [
                            'http://xmlns.com/foaf/0.1/depiction',
                            'http://xmlns.com/foaf/0.1/img',
                        ],
                    },
                    {
                        ...Ontodia.OWLStatsSettings,
                        fullTextSearch: {
                            prefix:
                                'PREFIX skos: <http://www.w3.org/2004/02/skos/core#>\n' +
                                'PREFIX :<https://purl.archive.org/urwriters#>\n' +
                                'PREFIX urb:<https://purl.archive.org/urbooks#>\n',
                            queryPattern: `
                                SELECT ?inst ?label WHERE {
                                    ?inst rdfs:label ?label .
                                    FILTER (regex(?label, "\${text}", "i"))
                                }
								LIMIT 100
                                `,
                        },
                        elementInfoQuery: `
                            CONSTRUCT {
                                ?inst rdf:type ?class;
                                    rdfs:label ?label;
                                    ?propType ?propValue.
                            }
                            WHERE {
                                OPTIONAL {?inst rdf:type ?class . }
                                OPTIONAL {?inst \${dataLabelProperty} ?label}
                                OPTIONAL {?inst ?propType ?propValue.
                                    FILTER (isLiteral(?propValue)) }
                                VALUES ?labelProp { rdfs:label foaf:name }
                                FILTER (?inst = \${ids})
                            }
                           `,
                    }
                ),
				new Ontodia.SparqlDataProvider(
                    {
                        name: 'Wiki',
                        endpointUrl: '/wiki',
                        imagePropertyUris: [
                            'http://xmlns.com/foaf/0.1/depiction',
                            'http://xmlns.com/foaf/0.1/img',
                        ],
                    },
                    {
                        ...Ontodia.OWLStatsSettings,
                        fullTextSearch: {
                            prefix:
                                'PREFIX skos: <http://www.w3.org/2004/02/skos/core#>\n' +
                                'PREFIX :<https://purl.archive.org/urwriters#>\n' +
                                'PREFIX urb:<https://purl.archive.org/urbooks#>\n',
                            queryPattern: `
                                SELECT ?inst ?label WHERE {
                                    ?inst rdfs:label ?label .
                                    FILTER (regex(?label, "\${text}", "i"))
                                }
								LIMIT 100
                                `,
                        },
                        elementInfoQuery: `
                            CONSTRUCT {
                                ?inst rdf:type ?class;
                                    rdfs:label ?label;
                                    ?propType ?propValue.
                            }
                            WHERE {
                                OPTIONAL {?inst rdf:type ?class . }
                                OPTIONAL {?inst \${dataLabelProperty} ?label}
                                OPTIONAL {?inst ?propType ?propValue.
                                    FILTER (isLiteral(?propValue)) }
                                VALUES ?labelProp { rdfs:label foaf:name }
                                FILTER (?inst = \${ids})
                            }
                        `,
                    }
                ),
			],
			{ mergeMode: 'fetchAll' }
		),
	});
}
export default class Graph extends React.Component {
	render() {
		const props = {
			onWorkspaceEvent: (e) => {				
				if (e === 'search:queryItems') {
					try {
						const search = window.$r.state.criteria["text"]
						this.setState({ currentEvent: `${e}: ${search}` });
						//call VENTI api
						state.set('ArcaEvent', {
							event: `${e}: ${search}`,
							timeStamp: new Date().toLocaleString(),
						})
					}
					catch {
						const search = 'no_available'
						this.setState({ currentEvent: `${e}: ${search}` });
						state.set('ArcaEvent', {
							event: `${e}: ${search}`,
							timeStamp: new Date().toLocaleString(),
						})
					}
				}
				else {
					this.setState({ currentEvent: `${e}` });
					state.set('ArcaEvent', {
						event: e,
						timeStamp: new Date().toLocaleString(),
					})
				}
			},
			languages: [
                { code: 'it', label: 'Italiano' },
                { code: 'en', label: 'English' },
                { code: 'de', label: 'Deutsch' },
            ],
			ref: onWorkspaceMounted,
		};
		return (<
			ConnectedOntodia {
			...props
			}
		/>
		);
	}
}
const mapStateToProps = state => {
	return {
		watermarkSvg: state.graph.watermarkSvg,
		watermarkUrl: state.graph.watermarkUrl,
		criteria: state.graph.criteria,
		language: state.graph.language,
		target: state.graph.target,
		searchResults: state.graph.searchResults,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		onSearchCriteriaChanged: (value) => dispatch({
			type: 'UPDATECRITERIA',
			criteria: value
		}),
		onSearchResultsChanged: (value) => dispatch({
			type: 'UPDATERESULTS',
			results: value
		}),
		onLanguageChange: (e) => {
			dispatch({
				type: 'LANGUAGECHANGE',
				currentEvent: `${e}`
			});
		},
		typeStyleResolver: (types) => {
			const writersAuthor = 'https://purl.archive.org/urwriters#Person';
			const writersWork = 'https://purl.archive.org/urbooks#Expression';
			const writerprize = 'https://purl.archive.org/urwriters#Prize';
			const writerminority = 'https://purl.archive.org/urwriters#Minority'
			const writerRole = 'https://purl.archive.org/urwriters#Role'
			switch (true) {
				case types.indexOf(writersAuthor) !== -1:
					return { color: '#E3B600' };
				case types.indexOf(writerRole) !== -1:
					return { color: '#507cff' };
				case types.indexOf(writerminority) !== -1:
					return { color: '#990987' };
				case types.indexOf(writersWork) !== -1:
					return { color: '#92d827' };
				case types.indexOf(writerprize) !== -1:
					return { color: '#fbe900' };
				default:
					return { color: '#A9A9A9' };
			}
		},
		onPointerDown: (element) => {
			dispatch({
				type: 'TAKETARGET',
				target: element.target
			});
		},
		viewOptions: {
			onIriClick: ({
				iri
			}) => window.open(iri),
		},
		leftPanelInitiallyOpen: true,
	};
};
export const ConnectedOntodia = connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(
	Ontodia.Workspace
)