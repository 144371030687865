import * as React from "react";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { useVenti } from 'venti';
import PersonIcon from '@material-ui/icons/Person';
import '../../styles/timeline.css'
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { db, arrayUnion } from '../../firebase/firebase';
import Button from '@material-ui/core/Button';

function TimelineComponent(props) {
    const state = useVenti() // VENTI this is needed so your component updates when state changes
    const LightTooltip = withStyles((theme) => ({
		tooltip: {
			backgroundColor: theme.palette.common.black,
			color: '#fff',
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	}))(Tooltip);
    const { 
		user,
        criteria,
		target } = props;
    // console.log(user)

    const handleDelete = () => {
        const usersRef = db.collection('history').doc(user.email);
        usersRef.delete().then(() => {
          console.log("Document successfully deleted!");
        }).catch((error) => {
          console.error("Error removing document: ", error);
        });
        props.setSelection([])
      }
        
    return (
        <div className = { state.get(`HistoryPanel.show`) ? 'mainTimeline' : 'mainTimelineInvisible' } > 
            <div className = 'targetTop' style={{fontSize: '9px'}}><span className = 'iconTop'><PersonIcon/></span>{user.email}</div>
            <Button onClick={handleDelete}>Clear history</Button>
            {props.selection.map((item, index) => (
                <Timeline key = {index}>
                    <TimelineEvent
                        title = {<span><span style={{fontSize: '9px'}}>TYPE: </span><span style={{fontSize: '10px'}}>{item.type}</span></span>}
                        createdAt={<div style={{
                            marginBottom: '3px',
                            color: 'rgb(128 128 128)',
                            borderRadius: 'opx',
                            paddingRight: '5px',
                            filter: 'brightness(0.8)',
                            background: '#ffffff6b',
                            fontSize: '8px',
                            textAlign:'-webkit-right'}}>
                            {item.timeStamp}
                            </div>}
                        icon={<SearchIcon/>}
                    >
                    <LightTooltip
                        enterDelay={500}
                        title={item.label}
                        placement='left-start'>
                        <a target="_blank" href={item.iri} style={{
                                background: 'white',
                                width: '-webkit-fill-available',
                                color: '#970000',
                                margin: '4px',
                                fontWeight: '600',
                                display: 'block',
                                overflow: 'hidden',
                        }}>{item.label}</a>
                    </LightTooltip>

                    </TimelineEvent>
                </Timeline>
            ))}
        </div>
    );
};

function mapStateToProps(state) {
	return {
		user: state.auth.user,
		criteria: state.graph.criteria,
		target: state.graph.target
	};
  }

  export default connect(mapStateToProps)(TimelineComponent);