import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import './style.css';

const Rating = ({ rating }) => {
 const numRating = parseFloat(rating);
 const fullStars = Math.floor(numRating);
 const partialStar = numRating - fullStars;

 const renderStars = (num) => {
  const stars = [];

  for (let i = 0; i < num; i++) {
   stars.push(<StarIcon className='fas' key={i} />);
  }

  if (partialStar > 0) {
   const percent = partialStar * 100;
   // const style = { width: `${percent}%` };
   stars.push(
    <StarHalfIcon className='fas fa-star-half-alt' key={num} />
   );
  }

  return stars;
 };

 return (
  <>
   <p className="rating">{renderStars(fullStars)}</p>
   <p>{rating}</p>

  </>
 );
};

export default Rating;
