import { useVenti } from 'venti';
import React, { useState } from 'react';
import { db, arrayUnion } from '../../firebase/firebase';

var volatile1='';
var timeEvent='';

var temp = '';

export default function Event (props) {

  const [selection, setSelection] = useState([])

  const state = useVenti() // VENTI this is needed so your component updates when state changes
  const user_mail = state.get(`UserLogin.email`) // VENTI get a property
  const login_time = state.get(`UserLogin.time`) // VENTI get a property

  
  if (volatile1 != state.get('ArcaEvent.event')) {
    volatile1 = state.get('ArcaEvent.event') // variabile temporanea
    timeEvent = state.get('ArcaEvent.timeStamp')
  }

  // CLOUD FIRESTORE GET DOCUMET
  // console.log(volatile, user_mail)

  if (user_mail != null && volatile1 == undefined) {
    const usersRef = db.collection('event').doc(user_mail);
    usersRef.get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) { //READ EVENT
          usersRef.onSnapshot((doc) => { // do stuff with the data
            if (temp != timeEvent) {
              setSelection(docSnapshot.data().events);
              temp = timeEvent
            }
          });
        }
    });
  } 

  else if (user_mail != null && timeEvent != null) {
    const usersRef = db.collection('event').doc(user_mail);
    usersRef.get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) { //READ DATA
          usersRef.onSnapshot((doc) => { // do stuff with the data
          if (temp != timeEvent) {
            setSelection(docSnapshot.data().events);
            temp = timeEvent
            //UPDATE DATA OF USER
            // console.log(temp);
            try {
              usersRef.update({  
                currentEvent: arrayUnion({
                  type: volatile1, 
                  timeStamp: timeEvent, 
                })
              }); 
            }
            catch {
              console.log('error')
            }
            
          }
            
          });
        } 
        else { // CREATE THE DOCUMENT
          usersRef.set({
            currentEvent: arrayUnion({
                type: volatile1, 
                timeStamp: timeEvent, 
              })
          }) 
        }
    });
  } 
  return (true)
}
