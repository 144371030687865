import React from "react";
import Button from '@material-ui/core/Button';

function Step2(props) {
  return (
    <div>
        <div>Chiama i tuoi amici e spiega loro come compilare il questionario entro 48 ore!</div>
        <div>Non appena il tempo sarà scaduto, se avrai vinto la sfida riceverai il regalo. In caso contrario, non potrai più riprovare.</div>
        <Button onClick={props.next}>
            AVANTI
        </Button>
    </div>
  );
}

export default Step2;
