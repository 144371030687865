import React, { Component } from 'react';
import GraphWorkspace from './GraphWorkspace';

import '../styles/home.css';

class Home extends Component {
	render() {
		//GLI STILI
		var elementstyle_main = {
			width: '100%',
			height: '100%',
			backgroundColor: 'white',
			paddingBottom: 0,
		};

		return (
			<div className="main" style={elementstyle_main}>
				<div className="PersistentDrawerRight">
					<GraphWorkspace />
				</div>
			</div>
		);
	}
}

export default Home;
