import BannerAnim, { Element } from "rc-banner-anim";
import TweenOne from "rc-tween-one";
import QueueAnim from "rc-queue-anim";

import "rc-banner-anim/assets/index.css";
import React from "react";

import "../styles/banner.css";
// import immagine from "../images/immagine.png";

export default function Banner() {
  const BgElement = Element.BgElement;

  return (
    <BannerAnim
      autoPlay
      autoPlaySpeed={9000}
      autoPlayEffect={false}
      prefixCls="banner-user"
    >
      <Element
        key="aaa"
        prefixCls="banner-user-elem"
        followParallax={{
          delay: 1000,
          data: [
            {
              id: "bg",
              value: 20,
              bgPosition: "50%",
              type: ["backgroundPositionX"]
            },
            { id: "title", value: -20, type: "x" },
            { id: "queue", value: 50, type: "x" },
            { id: "p", value: 40, type: "x" },
            { id: "JText", value: -30, type: "x" }
          ]
        }}
      >
        <BgElement
          id="bg"
          key="bg"
          className="bg"
          style={{
            background: "#fff0"
          }}
        />
        <QueueAnim id="queue" key="queue">
          <h1 className="banner-title" key="h1" id="title">
            An innovative system
          </h1>
          <p className="banner-text" id="p" key="p">
            able to extract and discover information from a source of texts
          </p>
        </QueueAnim>
        <TweenOne
          animation={{ y: 50, opacity: 0, type: "from", delay: 200 }}
          id="JText"
        >
          <p className="banner-text" id="p" key="p">
            through the visual exploration of data
          </p>
        </TweenOne>
      </Element>

      <Element
        key="aaa"
        prefixCls="banner-user-elem"
        followParallax={{
          delay: 1000,
          data: [
            {
              id: "bg",
              value: 20,
              bgPosition: "50%",
              type: ["backgroundPositionX"]
            },
            { id: "title", value: -20, type: "x" },
            { id: "queue", value: 50, type: "x" },
            { id: "p", value: 40, type: "x" },
            { id: "JText", value: -30, type: "x" }
          ]
        }}
      >
        <BgElement
          id="bg"
          key="bg"
          className="bg"
          style={{
            background: "#fff0"
          }}
        />
        <QueueAnim id="queue" key="queue">
          <h1 className="banner-title" key="h1" id="title">
            Easily deployable for small-medium sized organizations
          </h1>
          <p className="banner-text" id="p" key="p">
            For organizations that may not have the technical know-how and
            resources needed to build and maintain
          </p>
        </QueueAnim>
        <TweenOne
          animation={{ y: 50, opacity: 0, type: "from", delay: 200 }}
          id="JText"
        ></TweenOne>
        <TweenOne
          animation={{ y: 50, opacity: 0, type: "from", delay: 200 }}
          id="JText"
        >
          <p className="banner-text" id="p" key="p">
            a specifically designed knowledge graph and software system.
          </p>
        </TweenOne>
      </Element>

      <Element
        key="aaa"
        prefixCls="banner-user-elem"
        followParallax={{
          delay: 1000,
          data: [
            {
              id: "bg",
              value: 20,
              bgPosition: "50%",
              type: ["backgroundPositionX"]
            },
            { id: "title", value: -20, type: "x" },
            { id: "queue", value: 50, type: "x" },
            { id: "p", value: 40, type: "x" },
            { id: "JText", value: -30, type: "x" }
          ]
        }}
      >
        <BgElement
          id="bg"
          key="bg"
          className="bg"
          style={{
            background: "#fff0"
          }}
        />
        <QueueAnim id="queue" key="queue">
          <h1 className="banner-title" key="h1" id="title">
            Free software component
          </h1>
        </QueueAnim>
        <TweenOne
          animation={{ y: 50, opacity: 0, type: "from", delay: 200 }}
          id="JText"
        ></TweenOne>
        <TweenOne
          animation={{ y: 50, opacity: 0, type: "from", delay: 200 }}
          id="JText"
        >
          <p className="banner-text" id="p" key="p">
            The system is based on free software component.
          </p>
        </TweenOne>
      </Element>
    </BannerAnim>
  );
}
