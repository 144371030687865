import L from "leaflet";
import "leaflet/dist/leaflet.css";
import queryWorkPlace from "../authorView/query/WorkPlace";
import { useQuery } from "../useQuery";
import React, { useState, useEffect, useRef } from "react";
import Loading from "../../../LoadingSpinner/Loading";
import './style.css';

const Map = ({ targetIri }) => {
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState([]);
  const [itemList, itemListLoading] = useQuery(
    "/writers",
    queryWorkPlace(targetIri)
  );

  useEffect(() => {
    if (!itemList || itemListLoading) {
      return;
    }

    const newMarkers = itemList
      .map(({ inst, label, lat, lng }) => ({
        inst: inst?.value,
        label: label?.value,
        lat: parseFloat(lat?.value),
        lng: parseFloat(lng?.value),
      }))
      .filter(({ lat, lng }) => !isNaN(lat) && !isNaN(lng));

    setMarkers(newMarkers);
  }, [itemList, itemListLoading]);

  useEffect(() => {
    if (!markers.length || !mapRef.current) {
      return;
    }

    const bounds = L.latLngBounds(markers.map((m) => [m.lat, m.lng]));
    const map = L.map(mapRef.current).fitBounds(bounds);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
    }).addTo(map);

    markers.forEach(({ lat, lng, label }) => {
      L.marker([lat, lng])
        .addTo(map)
        .bindPopup(label);
    });

    return () => {
      map.remove();
    };
  }, [markers]);

  return (
    <div>
      {itemListLoading ? (
        <Loading />
      ) : itemList.length === 0 ? (
        <div className="noResults">There are no results to show.</div>
      ) : (
        <div id="map" ref={mapRef} style={{ height: "500px" }} />
      )}
    </div>
  );
};

export default Map;
