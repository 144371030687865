import React from 'react';
import HistoryIcon from '@material-ui/icons/History';
import '../../styles/menu_button.css';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { state } from 'venti';

export default function FullWidthGrid() {
	const [open, setOpen] = React.useState(false);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
		//call VENTI api
		state.set('eventsButton',{
			type: 'historyButton',
			timeStamp: new Date().toLocaleString(),
		})
	  };

	const LightTooltipInfo = withStyles((theme) => ({
		tooltip: {
			backgroundColor: theme.palette.common.white,
			color: 'rgba(0, 0, 0, 0.87)',
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	}))(Tooltip);

	return (
		<div className = 'history_button'>
			<LightTooltipInfo enterDelay={500} title="Open the history">
				<Button
					type="button"
					fullWidth
					variant="contained"
					color="primary"
					disableElevation
					className="button_history"
					onClick={handleToggle}
				>
					{open ? <span>HIDE<HistoryIcon/></span> : <HistoryIcon/>}
					
				</Button>
			</LightTooltipInfo>

			<div className={open ? 
				(
				//call VENTI api
				state.set('HistoryPanel',{
					show: true,
					  })
				) : (
				//call VENTI api
				state.set('HistoryPanel',{
					show: false,
					})
				)}>
			</div>
		</div>
	);
}