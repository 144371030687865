import React, { Component, useEffect } from 'react';

import { myAnalytics } from '../firebase/firebase';
import { state } from 'venti';
import { Row, Col } from "react-bootstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginUser } from '../actions';
import { withStyles } from '@material-ui/styles';
import Welcome from './Welcome';
import Logos from './Logos';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Banner from './Banner';
import VideoIntro from './VideoIntro';
import '../styles/login.css';

const styles = () => ({
	'@global': {
		body: {},
	},
	paper: {
		marginTop: 100,
		display: 'flex',
		padding: 20,
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		marginLeft: 'auto',
		marginRight: 'auto',
		// backgroundColor: '#f50057',
	},
	form: {
		marginTop: 1,
	},
	errorText: {
		color: '#f50057',
		marginBottom: 5,
		textAlign: 'center',
	},
});

class Login extends Component {
	state = { email: '', password: '' };

	handleEmailChange = ({ target }) => {
		this.setState({ email: target.value });
	};

	handlePasswordChange = ({ target }) => {
		this.setState({ password: target.value });
	};

	handleSubmit = () => {
		const { dispatch } = this.props;
		const { email, password } = this.state;
		dispatch(loginUser(email, password));
		myAnalytics.logEvent('login', { email });
		// VENTI login nuovo
		state.set('UserLogin', {
			email: this.state.email,
			time: new Date().toLocaleString(),
		})
	};

	render() {

		const { classes, loginError, isAuthenticated } = this.props;

		if (isAuthenticated) {
			// VENTI

			//myAnalytics.logEvent('login', isAuthenticated);
			// console.log("autentication", isAuthenticated)
			return <Redirect to="/" />;
		} else {
			return (
				<div className="homepage">

					<Container component="main" maxWidth="xs">
						<Welcome />
						<Paper className={classes.paper}>
							<Avatar className={classes.avatar}>
								<LockOutlinedIcon />
							</Avatar>
							<Typography component="h1" variant="h5">
								Sign in
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								onChange={this.handleEmailChange}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								onChange={this.handlePasswordChange}
							/>
							{loginError && (
								<p className={classes.errorText}>
									Incorrect email or password.
								</p>
							)}
							<Row className="justify-content-md-center text-center">
								<Col xs={6} md={6} >
									<Button
										type="button"
										fullWidth
										variant="contained"
										className='log_button'
										onClick={this.handleSubmit}
									>
										Sign In
									</Button>
								</Col>
								<Col xs={6} md={6} >
									<Button
										type="button"
										fullWidth
										variant="contained"
										className='reg_button'
										onClick={event => window.location.href = '/register'}
									>
										Register
									</Button>
								</Col>
							</Row>


						</Paper>
					</Container>

					<div className="container_slider">
						<div className="banner">
							<Banner />
						</div>
					</div>

					<VideoIntro />

					<Container style={{ textAlign: '-webkit-center', padding: '20px' }}>
						<p className='contactM'>CONTACT: <a className='contactUs' href="mailto:wl-kg@digital-humanities.it">wl-kg@digital-humanities.it</a></p>
					</Container>

					<Logos />
					<br></br>
					<br></br>
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		isLoggingIn: state.auth.isLoggingIn,
		loginError: state.auth.loginError,
		isAuthenticated: state.auth.isAuthenticated,
	};
}

export default withStyles(styles)(connect(mapStateToProps)(Login));