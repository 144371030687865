import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PathButton from '../pathfind/PathButton';


import '../../styles/menu_button.css';


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

export default function FullWidthGrid() {
	const classes = useStyles();

	return (
		<div className='path_button'>
			<Grid container  direction="row"  justify="center"  alignItems="center" container spacing={3}>
				<Grid direction="row" style={{ minWidth: 'fit-content', marginRight: "0px" }} item xs={1} sm={1}>
					<PathButton />
				</Grid>
			</Grid>
		</div>
	);
}