import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { db } from '../../../../firebase/firebase';
import { state } from 'venti';
import { useVenti } from 'venti';

function FinalStep(props) {   
    const state = useVenti() // VENTI this is needed so your component updates when state changes

    const StartChallenge = (props) => {
        const user_mail = state.get(`UserLogin.email`) // VENTI get a property       
        //CLOUD FIRESTORE SET OR UPDATE DATA 
        if (user_mail != null) {
            const usersRef = db.collection('challange').doc(user_mail);
            usersRef.get()
                .then((docSnapshot) => {
                if (docSnapshot.exists) {
                    // UPDATE challenge OF USER
                    try {
                    usersRef.update({  
                        answer: ({
                        try: true,
                        time: 48,
                        mail1: props.state.email1, 
                        mail2: props.state.email2, 
                        })
                    }); 
                    }
                    catch {
                    console.log('error')
                    }  
                } 
                else { // CREATE THE DOCUMENT
                    usersRef.set({  
                    answer: ({
                        try: true,
                        time: 48,
                        mail1: props.state.email1, 
                        mail2: props.state.email2, 
                        })
                    }); 
                }
            });
        }  
    }

    return (
        <div>
             <div onLoad={StartChallenge(props)}>Ora il team di Arca invierà gli account ai tuoi amici.</div>
            <div>Se completerai la sfida, potrai vincere l'accesso al sito di Arca per 6 mesi.</div>
        </div>
    );
}

export default FinalStep;
