import { useVenti } from 'venti';
import React, { useEffect, useState } from 'react';
import { db, arrayUnion } from '../../firebase/firebase';
import Button from '@material-ui/core/Button';
import Timeline from "./Timeline";
import { connect } from "react-redux";
// state.set({
//   items: [],
// })

var volatile = '';
var type = '';
var label = [];

var temp = '';

function History(props) {

  const {
    isAuthenticated,
    isVerifying,
    watermarkSvg,
    criteria,
    user,
    target } = props;

  const [selection, setSelection] = useState([])
  const [volatile, setVolatile] = useState()


  useEffect(() => {
    if (target != undefined) {
      setVolatile(target.iri)
    }
  }, [target]);

  useEffect(() => {
    try {
      const usersRef = db.collection('history').doc(user.email);

      usersRef.get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) { //READ HISTORY
            usersRef.onSnapshot((doc) => { // do stuff with the data
              if (temp != volatile) {
                setSelection(docSnapshot.data().selected_uri);
                temp = volatile
              }
            });
          }
          if (target != undefined && !docSnapshot.exists) {

            const stringValues = target._data.label.values
              .filter((key) => key.datatype === 'http://www.w3.org/2001/XMLSchema#string')
              .map((key) => key.text);


            usersRef.set({
              selected_uri: arrayUnion({
                iri: target.iri,
                type: type,
                label: stringValues,

                // type: type,
                // label: label, 
                timeStamp: new Date().toLocaleString("it")
              })
            })
          }
        });
    }
    catch {
      const exitfunc = 0
    }

  }, [volatile]);

  useEffect(() => {
    if (volatile != undefined) {
      const usersRef = db.collection('history').doc(user.email);
      if (target.iri.includes("resource")) {
        type = 'concept'
      }
      if (target.iri.includes("lerma")) {
        type = 'book'
      }
      const stringValues = target._data.label.values
        .filter((key) => key.datatype === 'http://www.w3.org/2001/XMLSchema#string')
        .map((key) => key.text);

      usersRef.update({
        selected_uri: arrayUnion({
          iri: target.iri,
          type: type,
          label: stringValues,
          timeStamp: new Date().toLocaleString("it")
        })
      })
    }

  }, [volatile]);


  // PAGE OF HISTORY
  return (
    <div>
      <Timeline selection={selection} setSelection={setSelection} />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    criteria: state.graph.criteria,
    target: state.graph.target
  };
}

export default connect(mapStateToProps)(History);