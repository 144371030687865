import React, { Component } from 'react';
import request from 'superagent'
import { useVenti } from 'venti';
import { state } from 'venti';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import '../../styles/pathfind.css';
import shiftClick from '../../images/shift_click.png';
import QueryPath from './QueryPath copy';
import DialogVideo from './DialogVideo';

const LightTooltip = withStyles((theme) => ({
	tooltip: {

		backgroundColor: '#c7c8ce00',
		width:'max-content',
		color: '#fff',
		boxShadow: 'none',
		fontSize: 11,
	},
}))(Tooltip);

class PathFind extends Component {
	constructor() {
		super();
		this.handleClick_primo = this.handleClick_primo.bind(this);
		this.handleClick_secondo = this.handleClick_secondo.bind(this);
		this.viewCommon = this.viewCommon.bind(this);
		this.hideCommon = this.hideCommon.bind(this);
		this.state = {
			buttonColor_primo: '',
			buttonColor_secondo: '',
			first_label: '',
			second_label: '',
			first_iri:'',
			second_iri:'',
			view: false,
		};
	  }
	

	handleClick_primo(e) {
		if (e.shiftKey) {
			this.setState({
				buttonColor_primo: 'green',
				first_label: this.props.Label,
				first_iri: this.props.Iri,
				view: false,
			});	
		}
	}
	
	handleClick_secondo(e) {	
		if (e.shiftKey) {
			this.setState({
				buttonColor_secondo: 'red',
				second_label: this.props.Label,
				second_iri: this.props.Iri,
				view: false,
			});
		}
	}

	viewCommon(e) {	
		this.setState({
			view: true,
		});
	}

	hideCommon(e) {	
		this.setState({
			buttonColor_primo: '',
			buttonColor_secondo: '',
			first_label: '',
			second_label: '',
			first_iri:'',
			second_iri:'',
			view: false,
		});
	}
	
	render() {
		
		
		return (
			<div className="main_path_container">
				<p className = 'path_title' >Trace path</p>
				<DialogVideo/>
				<p className = 'path_text'>Current selection:</p>
				<p className="path_label">{this.props.Label}</p>

				<div className="button_path"
					onClick={this.handleClick_primo}
					style={{backgroundColor: this.state.buttonColor_primo,padding: '1px'}}>
					<img src = {shiftClick} className = 'instruction'/>	
					{this.state.first_label ? ( <p className = 'targetLabel'>{this.state.first_label}</p>) : (<span></span>)}
				</div>	

				<div className="button_path"
					onClick={this.handleClick_secondo}
					style={{backgroundColor: this.state.buttonColor_secondo,padding: '1px'}}>
					<img src = {shiftClick} className = 'instruction'/>	
					{this.state.second_label ? ( <p className = 'targetLabel'>{this.state.second_label}</p>) : (<span></span>)}
				</div>	

				{(()=> {
					if (this.state.first_iri!='' && this.state.second_iri!='' && !this.state.view) {
						return (
							// PIENI
							<Button 
							onClick={this.viewCommon}
							variant="contained"
							color="primary"
							disableElevation="true"
							>
							GO
							</Button>
						)
					}  
					else if (this.state.first_iri!='' && this.state.second_iri!='' && this.state.view) {
						return (
							// Elimina i risultati
							<Button 
							onClick={this.hideCommon}
							variant="contained"
							color="primary"
							disableElevation="true"
							>
							CLEAR
							</Button>
						)
					}
				})()}

				{!this.state.view ? (
					// falso
					<p></p>
					) : (
					// vero
					<QueryPath first = {this.state.first_iri} second = {this.state.second_iri}/>
				)}

			</div>
		);
	}
}

export default PathFind;