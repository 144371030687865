import React from "react";
import request from 'superagent'

import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Highlighter from 'react-fast-highlighter'

import "../../../styles/call_sparql.css";


class Snippet extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      itemList: [],
      Link: this.props.Link,
      SelectedConcept: this.props.SelectedConcept,
    };
  }
  
  //CREAZIONE DI LISTE E QUERY PER FILTRI
  callQuery() {
      //LISTA ORDINA DATA CRESCENTE 
      request
      .get('/sparql-endpoint')
      .accept('application/json')

      .query({ query: 
        `     
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX owl:  <http://www.w3.org/2002/07/owl#>
        PREFIX bds: <http://www.bigdata.com/rdf/search#>
        PREFIX dc:   <http://purl.org/dc/elements/1.1/>
        PREFIX lerma: <http://www.lerma.org/>

        SELECT ?inst ?class ?label ?sentence ?book 
        WHERE {
        {
            SELECT DISTINCT ?inst  WHERE {
            { ?inst <http://www.lerma.org/containEntity> <` + this.state.SelectedConcept + `> . FILTER(isIri(?inst)) }
            }
        }
        OPTIONAL {?inst rdf:type ?foundClass}
        BIND (coalesce(?foundClass, owl:Thing) as ?class)
        OPTIONAL {?inst rdfs:label ?label}
        OPTIONAL {?inst dc:description ?sentence}
        OPTIONAL {?inst lerma:intoBook ?book}
        FILTER(?book = <` + this.state.Link + `>)
        }  
        ` 
      })

      .buffer(true)

      .end((err, resp) => {
        if (!err) {
          var result = JSON.parse(resp.text).results.bindings
          //console.log(result)
          
          this.setState({
            itemList: result, 
              });
          
        }
       }
      )
  }

  componentDidMount () {
    this.callQuery();
  }

  render() {
      //console.log(this.state.itemList)
    
    return (
        <TableContainer>
            <TableBody>
                {this.state.itemList.map((rowdata, i) => {
                    return (
                        <TableRow key={i}>
                           <TableCell align="left">
                            <Highlighter
                              text={rowdata.sentence.value}
                              highlightText={rowdata.label.value}
                            />
                          </TableCell>
                        </TableRow>   
                    );
                })}
            </TableBody>
        </TableContainer>
  );
}}


export default Snippet;