import React from 'react';
import request from 'superagent';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import UndoIcon from '@material-ui/icons/Undo';
import CustomizedDialogs from '../query_sparql/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';

import '../../styles/lista.css';

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.black,
		color: '#fff',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);

class Lista extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			itemList: [],
			link_query: 'zero',
			link_query_1: 'uno',
		};
	}

	//CREAZIONE DI LISTE E QUERY PER FILTRI
	callQuery() {
		//LISTA ORDINA DATA CRESCENTE
		request
			.get('/sparql-endpoint')
			.accept('application/json')

			.query({
				query: `PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
						PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
						PREFIX owl:  <http://www.w3.org/2002/07/owl#>
					
								PREFIX bds: <http://www.bigdata.com/rdf/search#>
					
					
							SELECT ?inst ?img ?class ?label ?blankType 
							WHERE {
								{
									SELECT DISTINCT ?inst  WHERE {
										
										{ ?inst <http://www.w3.org/1999/02/22-rdf-syntax-ns#type> <http://lerma.org/Book> . FILTER(isIri(?inst)) }
										
										
										
									}

								}
								OPTIONAL {?inst rdf:type ?foundClass}
								OPTIONAL {?inst foaf:depiction ?img}
								BIND (coalesce(?foundClass, owl:Thing) as ?class)
								OPTIONAL {?inst rdfs:label ?label}
								
			   } `,
			})

			.buffer(true)

			.end((err, resp) => {
				if (!err) {
					var result = JSON.parse(resp.text).results.bindings;
					//console.log(result);

					this.setState({
						itemList: result,
					});
				}
			});
	}
	brokenImage() {
		//IS THE IMAGE BROKEN?
		var images = document.querySelectorAll('img');
		var errorHandler = function() {
		  this.src='http://195.231.2.149/erma_assets/Copertine/def_cop.jpg';
		}
		for (var i = 0; i < images.length; i++) {
		  images[i].onerror = errorHandler
		}
	  }
	
	componentDidMount () {
	this.callQuery();
	this.brokenImage();
	}

	componentDidUpdate(){
		this.brokenImage();
	}
	  
	  
	render() {
			this.brokenImage();

		return (
			<div className="intro">

				<div className='title_list'>
					<p className='back'>
						<LightTooltip placement='right-start' enterDelay={500} title="Go to the exploration of the contents">			
							<a href='/'><UndoIcon style={{ color: 'white', fontSize: 50 }}/></a>
						</LightTooltip>
					</p>
					<p className='title'>List of books</p>
				</div>

				<div className="lista">
					<GridList cellWidth={'auto'} cols={9} cellHeight={'auto'} >
						<GridListTile
							key="Subheader"
							style={{ width: '-webkit-fill-available', position: 'absolute!important', height: 'auto' }}
						></GridListTile>
						{this.state.itemList.map((rowdata, i) => (
							<GridListTile key={rowdata.label.value}>
								<a
									href={rowdata.inst.value}
									target="_blank"
									rel="noopener noreferrer"
									data-toggle="tooltip"
									data-placement="bottom"
									
									title={rowdata.label.value}
								>
									<img className="img_imagelink" src={rowdata.img.value}/>
									<GridListTileBar
										className="gridlist"
										title={rowdata.label.value}
									/>
								</a>
							</GridListTile>
						))}
					</GridList>
				</div>
			</div>
		);
	}
}

export default Lista;
