import * as React from "react";
import { useVenti } from 'venti';
import PersonIcon from '@material-ui/icons/Person';
import '../../styles/challenger.css'
import SearchIcon from '@material-ui/icons/Search';
import ImgChallenge from '../../images/challenge.png'
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';

export default (props) => {
    const show = props.selection.try
    const timeChallenge = props.selection.time
    
    const LightTooltip = withStyles((theme) => ({
		tooltip: {
			backgroundColor: theme.palette.common.black,
			color: '#fff',
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	}))(Tooltip);
    return (
        <div className = {show ? 'mainChallanger' : 'mainChallangerInvisible'}> 
            <LightTooltip
                enterDelay={500}
                title="Complete the challenge! You still have some hours"
                placement='left-start'>
                <p><img className = 'imgChallenge' src = {ImgChallenge}></img></p>
            </LightTooltip>
        </div>
    );
};
