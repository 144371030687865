import Image01 from "./images/01.png"
import Video01 from "../../video/01.mp4"
import Video02 from "../../video/02.mp4"
import Video03 from "../../video/03.mp4"
import Video04 from "../../video/04.mp4"
import demo from "../../video/wlkglong.mp4"
import team from "../../video/wlteam.mp4"

export default [
    {
        name: 'Demo',
        description: 'WL-KG - Demo',
        image: Image01,
        video: demo,
        css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        height: 265,
        
    },
    {
        name: 'Who we are',
        description: 'WL-KG - Team',
        image: Image01,
        video: team,
        css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        height: 265,
        
    },
    // {
    //     name: 'The colors of the entities',
    //     description: 'ARCA - tutorial',
    //     image: Image01,
    //     video: Video01,
    //     css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    //     height: 144,
    // },
    // {
    //     name: 'Tools',
    //     description: 'ARCA - tutorial',
    //     image: Image01,
    //     video: Video02,
    //     css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    //     height: 144,
    // },
    // {
    //     name: 'The books button',
    //     description: 'ARCA - tutorial',
    //     image: Image01,
    //     video: Video03,
    //     css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    //     height: 144,
    // },
    // {
    //     name: 'Search and Serendipity',
    //     description: 'ARCA - tutorial',
    //     image: Image01,
    //     video: Video04,
    //     css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    //     height: 144,
    // },
    // {
    //     name: 'Saint Petersburg',
    //     description: '#f5f7fa → #c3cfe2',
    //     image: Image01,
    //     css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    //     height: 200,
    // },
    // {
    //     name: 'Deep Blue',
    //     description: '#f5f7fa → #c3cfe2',
    //     image: Image01,
    //     css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    //     height: 200,
    // },
//   {
//     name: 'Ripe Malinka',
//     description: '#f093fb → #f5576c',
//     css: 'linear-gradient(120deg, #f093fb 0%, #f5576c 100%)',
//     height: 200,
//   },
//   {
//     name: 'Perfect White',
//     description: '#fdfbfb → #ebedee',
//     css: 'linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%)',
//     height: 200,
//   },
//   {
//     name: 'Near Moon',
//     description: '#5ee7df → #b490ca',
//     css: 'linear-gradient(to top, #5ee7df 0%, #b490ca 100%)',
//     height: 200,
//   },
//   {
//     name: 'Wild Apple',
//     description: '#d299c2 → #fef9d7',
//     css: 'linear-gradient(to top, #d299c2 0%, #fef9d7 100%)',
//     height: 100,
//   },
//   {
//     name: 'Ladoga Bottom',
//     description: '#ebc0fd → #d9ded8',
//     css: 'linear-gradient(to top, #ebc0fd 0%, #d9ded8 100%)',
//     height: 200,
//   },
//   {
//     name: 'Sunny Morning',
//     description: '#f6d365 → #fda085',
//     css: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
//     height: 100,
//   },
//   {
//     name: 'Lemon Gate',
//     description: '#96fbc4 → #f9f586',
//     css: 'linear-gradient(to top, #96fbc4 0%, #f9f586 100%)',
//     height: 200,
//   },
//   {
//     name: 'Salt Mountain',
//     description: ' #FFFEFF → #D7FFFE',
//     css: 'linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%)',
//     height: 100,
//   },
//   {
//     name: 'Soft Grass',
//     description: ' #c1dfc4 → #deecdd',
//     css: 'linear-gradient(to top, #c1dfc4 0%, #deecdd 100%)',
//     height: 200,
//   },
//   {
//     name: 'Japan Blush',
//     description: ' #ddd6f3 → #faaca8',
//     css: 'linear-gradient(-20deg, #ddd6f3 0%, #faaca8 100%, #faaca8 100%)',
//     height: 100,
//   },
]