import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { registerUser } from '../actions';
import Welcome from './Welcome';
import Logos from './Logos';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Banner from './Banner';
import { withStyles } from '@material-ui/styles';

import React, { Component, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import '../styles/login.css';


const styles = () => ({
    '@global': {
        body: {},
    },
    paper: {
        marginTop: 100,
        display: 'flex',
        padding: 20,
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#f50057',
    },
    form: {
        marginTop: 1,
    },
    errorText: {
        color: '#f50057',
        marginBottom: 5,
        textAlign: 'center',
    },
});

class Register extends Component {
    state = { email: '', password: '' };

    handleEmailChange = ({ target }) => {
        this.setState({ email: target.value });
    };

    handlePasswordChange = ({ target }) => {
        this.setState({ password: target.value });
    };

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { email, password } = this.state;

        dispatch(registerUser(email, password));
    };

    render() {
        const { registration, classes, isAuthenticated } = this.props;
        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        // console.log(protocol, domain, port)
        if (isAuthenticated) {
            return <Redirect to="/" />;
        }
        else {
            return (
                <div className="homepage">
                    <Container component="main" maxWidth="xs">
                        <Welcome />
                        <Paper className={classes.paper}>
                            {/* <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar> */}
                            <Typography component="h1" variant="h5">
                                New Registration
                            </Typography>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                label="Email Address"
                                type="email"
                                name="email"
                                onChange={this.handleEmailChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                onChange={this.handlePasswordChange}
                            />
                            {registration.message && (
                                <Typography component="p" className={classes.errorText}>
                                    {registration.message}
                                </Typography>
                            )}
                            <Row className="justify-content-md-center text-center">
                                <Col xs={6} md={6} >
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        className='reg_button'
                                        onClick={this.handleSubmit}
                                    >
                                        Register
                                    </Button>
                                </Col>
                                <Col xs={6} md={6} >
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        className='log_button'
                                        onClick={event => window.location.href = '/login'}
                                    >
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                        </Paper>
                    </Container>
                    <div className="container_slider">
                        <div className="banner">
                            <Banner />
                        </div>
                    </div>
                    <Logos />
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        registration: state.auth.registration,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

export default withStyles(styles)(connect(mapStateToProps)(Register));