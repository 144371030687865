import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Grid
} from '@material-ui/core';
import { useVenti } from 'venti';

function UserFeedback({ formOpen, handleFormClose, selectedIri, selectedLabel }) {
  const state = useVenti();
  const [formData, setFormData] = useState({
    name: '',
    email: state.get('UserLogin.email'),
    type: '',
    sourceLink: '',
    comment: ''
  });
  const [triples, setTriples] = useState([]);
  const [selectedTriple, setSelectedTriple] = useState(null);
  const [showTripleSelection, setShowTripleSelection] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // States for adding a new triple
  const [newSubject, setNewSubject] = useState('');
  const [newPredicate, setNewPredicate] = useState('');
  const [newObject, setNewObject] = useState('');
  const [predicates, setPredicates] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subjectFilter, setSubjectFilter] = useState('');
  const [predicateFilter, setPredicateFilter] = useState('');
  const [objectFilter, setObjectFilter] = useState('');

  const fetchTriples = useCallback(async (iri) => {
    try {
      const response = await fetch('\writers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/sparql-results+json',
        },
        body: `query=${encodeURIComponent(`
          define sql:big-data-const 0

          PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
          PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
          PREFIX owl:  <http://www.w3.org/2002/07/owl#>
          PREFIX bds: <http://www.bigdata.com/rdf/search#>
          PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
          PREFIX :<https://purl.archive.org/urwriters#>
          PREFIX urb:<https://purl.archive.org/urbooks#>

          SELECT DISTINCT ?p ?pLabel ?o ?oLabel
          WHERE {
            <${iri}> ?p ?o .
            OPTIONAL { ?p rdfs:label ?pLabel }
            OPTIONAL { ?o rdfs:label ?oLabel }
          }
          LIMIT 100
        `)}`,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.results.bindings.length === 0) {
        console.warn('No triples found for:', iri);
        return;
      }

      setTriples(data.results.bindings.map(binding => ({
        predicate: binding.p.value,
        predicateLabel: binding.pLabel ? binding.pLabel.value : binding.p.value,
        object: binding.o.value,
        objectLabel: binding.oLabel ? binding.oLabel.value : binding.o.value,
      })));
    } catch (error) {
      console.error('Error fetching triples:', error);
    }
  }, []);

  const fetchPredicatesAndClasses = useCallback(async () => {
    try {
      const response = await fetch('\writers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/sparql-results+json',
        },
        body: `query=${encodeURIComponent(`
          define sql:big-data-const 0

          PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
          PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
          PREFIX owl:  <http://www.w3.org/2002/07/owl#>
          PREFIX bds: <http://www.bigdata.com/rdf/search#>
          PREFIX skos: <http://www.w3.org/2004/02/skos/core#>

          SELECT DISTINCT ?class ?classLabel ?property ?propertyLabel
          WHERE {
            ?class a owl:Class .
            ?class rdfs:label ?classLabel .
            ?property a rdf:Property .
            ?property rdfs:label ?propertyLabel .
          }
            LIMIT 100
        `)}`,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const uniqueClasses = [];
      const classLabels = new Set();
      data.results.bindings.forEach(binding => {
        if (!classLabels.has(binding.classLabel.value)) {
          classLabels.add(binding.classLabel.value);
          uniqueClasses.push({
            uri: binding.class.value,
            label: binding.classLabel.value
          });
        }
      });

      const uniquePredicates = [];
      const predicateLabels = new Set();
      data.results.bindings.forEach(binding => {
        if (!predicateLabels.has(binding.propertyLabel.value)) {
          predicateLabels.add(binding.propertyLabel.value);
          uniquePredicates.push({
            uri: binding.property.value,
            label: binding.propertyLabel.value
          });
        }
      });

      setClasses(uniqueClasses);
      setPredicates(uniquePredicates);
    } catch (error) {
      console.error('Error fetching predicates and classes:', error);
    }
  }, []);

  useEffect(() => {
    if (selectedIri) {
      fetchTriples(selectedIri);
    }
  }, [selectedIri, fetchTriples]);

  useEffect(() => {
    fetchPredicatesAndClasses();
  }, [fetchPredicatesAndClasses]);

  useEffect(() => {
    if (!selectedTriple && triples.length > 0) {
      setSelectedTriple(triples[0]);
    }
  }, [selectedTriple, triples]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleTripleChange = (e) => {
    const selectedTripleIndex = e.target.value;
    setSelectedTriple(triples[selectedTripleIndex]);
  };

  const handleConfirmTriple = () => {
    setShowTripleSelection(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let dataToSave = { ...formData };

    if (formData.type === 'report' && selectedTriple ) {
      dataToSave = {
        ...dataToSave,
        iri: selectedIri,
        subject: selectedTriple.subject,
        predicate: selectedTriple.predicate,
        object: selectedTriple.object
      };
      console.log('Saving to reporting:', dataToSave);
    } else if (formData.type === 'add') {
      dataToSave = {
        ...dataToSave,
        newSubject,
        newPredicate,
        newObject
      };
      console.log('Saving to suggestions:', dataToSave);
    }

    setSubmitted(true);
    setTimeout(() => {
      setSubmitted(false);
      handleFormClose();
    }, 3000);
  };

  const filteredClasses = classes.filter(cls => cls.label.toLowerCase().includes(subjectFilter.toLowerCase()));
  const filteredPredicates = predicates.filter(pred => pred.label.toLowerCase().includes(predicateFilter.toLowerCase()));
  const filteredObjects = classes.filter(cls => cls.label.toLowerCase().includes(objectFilter.toLowerCase()));

  if (submitted) {
    return (
      <Dialog open={formOpen} onClose={handleFormClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Thank you for your feedback!</DialogTitle>
        <DialogContent>
          <Typography>Your feedback has been submitted successfully.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={formOpen} onClose={handleFormClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Report an Issue or Add Information</DialogTitle>
      <DialogContent>
        <p>To report an issue or add information to the graph, please fill out the form below.</p>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="dense">
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              name="type"
              value={formData.type}
              onChange={handleInputChange}
              required
            >
              <MenuItem value="report">Report an Issue</MenuItem>
              <MenuItem value="add">Add Information</MenuItem>
            </Select>
          </FormControl>
          {formData.type === 'report' && selectedIri && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Selected IRI: {selectedLabel.replace(/[\"\[\]]/g, '')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmTriple}
                style={{ width: '200px!important' }}
              >
                Confirm
              </Button>
              {showTripleSelection && (
                <FormControl fullWidth margin="dense">
                  <InputLabel id="triple-label">Select Triple</InputLabel>
                  <Select
                    labelId="triple-label"
                    id="triple"
                    name="triple"
                    value={selectedTriple ? triples.indexOf(selectedTriple) : ''}
                    onChange={handleTripleChange}
                    required
                  >
                    {triples.map((triple, index) => (
                      <MenuItem key={index} value={index}>
                        {`${triple.predicateLabel} - ${triple.objectLabel}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {formData.type === 'add' && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="subject-filter"
                    label="Filter Classes for Subject"
                    type="text"
                    fullWidth
                    value={subjectFilter}
                    onChange={(e) => setSubjectFilter(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="subject-label">Select Class for Subject</InputLabel>
                    <Select
                      labelId="subject-label"
                      id="subject"
                      name="subject"
                      value={newSubject}
                      onChange={(e) => setNewSubject(e.target.value)}
                      required
                    >
                      {filteredClasses.map((cls, index) => (
                        <MenuItem key={index} value={cls.uri}>
                          {cls.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <TextField
                margin="dense"
                id="subject-label"
                label="Subject Label"
                type="text"
                fullWidth
                value={formData.subjectLabel}
                onChange={(e) => setFormData({ ...formData, subjectLabel: e.target.value })}
                required
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="predicate-filter"
                    label="Filter Predicates"
                    type="text"
                    fullWidth
                    value={predicateFilter}
                    onChange={(e) => setPredicateFilter(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="predicate-label">Select Predicate</InputLabel>
                    <Select
                      labelId="predicate-label"
                      id="predicate"
                      name="predicate"
                      value={newPredicate}
                      onChange={(e) => setNewPredicate(e.target.value)}
                      required
                    >
                      {filteredPredicates.map((pred, index) => (
                        <MenuItem key={index} value={pred.uri}>
                          {pred.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="object-filter"
                    label="Filter Classes for Object"
                    type="text"
                    fullWidth
                    value={objectFilter}
                    onChange={(e) => setObjectFilter(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="object-label">Select Class for Object</InputLabel>
                    <Select
                      labelId="object-label"
                      id="object"
                      name="object"
                      value={newObject}
                      onChange={(e) => setNewObject(e.target.value)}
                      required
                    >
                      {filteredObjects.map((cls, index) => (
                        <MenuItem key={index} value={cls.uri}>
                          {cls.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <TextField
                margin="dense"
                id="object-label"
                label="Object Label"
                type="text"
                fullWidth
                value={formData.objectLabel}
                onChange={(e) => setFormData({ ...formData, objectLabel: e.target.value })}
                required
              />
              <TextField
                margin="dense"
                id="sourceLink"
                label="Source Link"
                type="url"
                fullWidth
                name="sourceLink"
                value={formData.sourceLink}
                onChange={handleInputChange}
                required
              />
            </>
          )}
          <TextField
            margin="dense"
            id="comment"
            label="Comment"
            type="text"
            fullWidth
            multiline
            rows={4}
            name="comment"
            value={formData.comment}
            onChange={handleInputChange}
            inputProps={{ maxLength: 250 }}
            helperText={`${formData.comment.length}/250`}
          />
          <DialogActions>
            <Button onClick={handleFormClose} color="primary">Cancel</Button>
            <Button type="submit" color="primary">Submit</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default UserFeedback;

             
