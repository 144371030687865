import React, { useState, useEffect, useCallback } from 'react';
import { formValueSelector } from 'redux-form';
import request from 'superagent'
import "../../styles/path_call_sparql.css";

function intersection() {
	var result = [];
  var lists;
  
  if(arguments.length === 1) {
  	lists = arguments[0];
  } else {
  	lists = arguments;
  }
  
  for(var i = 0; i < lists.length; i++) {
  	var currentList = lists[i];
  	for(var y = 0; y < currentList.length; y++) {
    	var currentValue = currentList[y];
      if(result.indexOf(currentValue) === -1) {
        var existsInAll = true;
        for(var x = 0; x < lists.length; x++) {
          if(lists[x].indexOf(currentValue) === -1) {
            existsInAll = false;
            break;
          }
        }
        if(existsInAll) {
          result.push(currentValue);
        }
      }
    }
  }
  return result;
}


function QueryPath(props) {
  
  const [listFull_dbpedia_1, setListFull_dbpedia_1] = useState([])
  const [listFull_dbpedia_2, setListFull_dbpedia_2] = useState([])
  const [commonsItem_dbpedia, setCommonsItem_dbpedia] = useState([])
  const [listFull_arca_1, setListFull_arca_1] = useState([])
  const [listFull_arca_2, setListFull_arca_2] = useState([])
  const [commonsItem_arca, setCommonsItem_arca] = useState([])
  const [definitiveList, setDefinitiveList] = useState([])
  const [start, setStart] = useState(false)
  const [DbAr, setDbAr] = useState(false)
  const { first, second } = props

  useEffect(() => {

    queryDbpedia(first, 'first');
    queryDbpedia(second, 'second');
    queryArca(first, 'first');
    queryArca(second, 'second');
  }, []);

  function queryArca(selected, selected_label) {
    request
    .get('/sparql-endpoint')
    .accept('application/json')

    .query({ query: 
     `
      PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
      PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
      PREFIX owl:  <http://www.w3.org/2002/07/owl#>
      PREFIX dbo: <http://dbpedia.org/ontology/>

      SELECT ?inst ?class ?label ?blankType 
      WHERE {
        {
          SELECT DISTINCT ?inst  
            WHERE {        
                { <` + selected + `> ?link ?inst . FILTER(isIri(?inst)) } UNION { ?inst ?link <` + selected + `> . FILTER(isIri(?inst)) }   
            }     
            
        }               
        OPTIONAL {?inst rdf:type ?foundClass. FILTER (!contains(str(?foundClass), 'http://dbpedia.org/class/yago'))}
        BIND (coalesce(?foundClass, owl:Thing) as ?class)
        OPTIONAL {?inst rdfs:label ?label}
        }
      ` 
    })

    .buffer(true)

    .end((err, resp) => {
      if (!err) {
        var result = JSON.parse(resp.text).results.bindings

        if (selected_label == 'first') {
          var ArrlistFull_arca_1 = []
          for (var key in result) {
            ArrlistFull_arca_1.push(result[key]['inst']['value']);
          }
          setListFull_arca_1(ArrlistFull_arca_1)
        }

        if (selected_label == 'second') {
          var ArrlistFull_arca_2 = []
          for (var key in result) {
            ArrlistFull_arca_2.push(result[key]['inst']['value']);
          }
          setListFull_arca_2(ArrlistFull_arca_2);
        }

      }
    })
  }

  function queryDbpedia(selected, selected_label) {
    request
    .get('/dbpedia')
    .accept('application/json')

    .query({ query: 
      `
      PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
      PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
      PREFIX owl:  <http://www.w3.org/2002/07/owl#>
      PREFIX dbo: <http://dbpedia.org/ontology/>

      SELECT ?inst ?class ?label ?blankType 
        WHERE {
          {
            SELECT DISTINCT ?inst  
              WHERE {        
                  { <` + selected + `> ?link ?inst . FILTER(isIri(?inst)) } UNION { ?inst ?link <` + selected + `> . FILTER(isIri(?inst)) }   
              
              
              }
          }            
        OPTIONAL {?inst rdf:type ?foundClass. FILTER (!contains(str(?foundClass), 'http://dbpedia.org/class/yago'))}
        BIND (coalesce(?foundClass, owl:Thing) as ?class)
        OPTIONAL {?inst rdfs:label ?label}
        } 
      ` 
    })

    .buffer(true)

    .end((err, resp) => {
      if (!err) {
        var result = JSON.parse(resp.text).results.bindings

        if (selected_label == 'first') {
          var ArrlistFull_dbpedia_1 = []
          for (var key in result) {
            ArrlistFull_dbpedia_1.push(result[key]['inst']['value']);
          }
          setListFull_dbpedia_1(ArrlistFull_dbpedia_1);

        }

        if (selected_label == 'second') {
          var ArrlistFull_dbpedia_2 = []
          for (var key in result) {
            ArrlistFull_dbpedia_2.push(result[key]['inst']['value']);
          }
          setListFull_dbpedia_2(ArrlistFull_dbpedia_2);
        }

      }
    })
  }

  function _onClick (e) {
    e.preventDefault()
  }

  useEffect(() => {

      if ( listFull_arca_1.length != 0 && listFull_arca_2.lenght != 0 ) {
        var full1_full2_arca = intersection([listFull_arca_1, listFull_arca_2])
        setCommonsItem_arca(full1_full2_arca)
      }

      if ( listFull_dbpedia_1.length != 0 && listFull_dbpedia_2.length != 0 ) {
        var full1_full2_dbpediaFiltered = intersection([listFull_dbpedia_1, listFull_dbpedia_2])
        setCommonsItem_dbpedia(full1_full2_dbpediaFiltered);
      }

      setStart(true)
      setDbAr(true)
  }, [listFull_arca_1,listFull_arca_2, listFull_dbpedia_1, listFull_dbpedia_2]);


  if (start == false) {
    return (
      <div className = 'main_path_list'>
        <div>...loading</div>
      </div>
    );
  }
  if (start == true && DbAr == true) {
    return (
      <div className = 'main_path_list'>
        {commonsItem_dbpedia.map((rowdata) => (
          <div className='item_path' key={rowdata}>
            <a href={rowdata} onClick={e => _onClick(e)}>
              {rowdata}
            </a>
          </div>
        ))}
        {commonsItem_arca.map((rowdata) => (
          <div className='item_path' key={rowdata}>
            <a href={rowdata} onClick={e => _onClick(e)}>
              {rowdata}
            </a>
          </div>
        ))}
      </div>
    );
  }
  if (start == true && DbAr == false) {
    return (
      <div className = 'main_path_list'>
        No results
      </div>
    );
  }
}

export default QueryPath;
