import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {
  Drawer, AppBar, Toolbar, CssBaseline, Typography, Divider,
  IconButton, Tooltip, Button,
} from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import { useVenti } from 'venti';
import { Helmet } from 'react-helmet';
import GraphComponent from './Graph';
import History from '../reducers/history/services';
import Event from '../reducers/events/services';
import EventButton from '../reducers/buttons/services';
import UseApi from './UseApi';
import Challenger from '../reducers/challenge/services';
import LogoutButton from './Logout_button';
import MenuFab from './menu_fab/Fab';
import Tabs from './query_sparql/App_tabs/App_tabs';
import UserFeedback from './UserFeedback/UserFeedback';

import '../styles/GraphWorkspace.css';
import '../styles/graph.css';

const drawerWidth = 380;
const TITLE = 'WLKG - World Literature Knowledge Graph';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: { flexGrow: 1 },
  hide: { display: 'none' },
  drawer: { width: drawerWidth, flexShrink: 0 },
  drawerPaper: { width: drawerWidth },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

function GraphWorkspace({ target }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const [currentIri, setCurrentIri] = useState();
  const [currentLabel, setCurrentLabel] = useState();
  const state = useVenti();

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
    state.set('eventsButton', {
      type: 'booksButton',
      timeStamp: new Date().toLocaleString(),
    });
  }, [state]);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
  };

  useEffect(() => {
    if (target) {
      try {
        const labelValues = target._data.label.values
          .filter(key => key.datatype === 'http://www.w3.org/2001/XMLSchema#string')
          .map(key => key.text);
        setCurrentIri(target.iri);
        setCurrentLabel(JSON.stringify(labelValues));
      } catch (error) {
        console.error('Error processing target:', error);
        setCurrentIri(target.iri);
        setCurrentLabel('null');
      }
    }
  }, [target]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, { [classes.appBarShift]: open })}
      >
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
            <div className="Arca">
              <GraphComponent />
            </div>
            <LogoutButton />
            <MenuFab />
            <History />
            <Event />
            <EventButton />
            <Challenger />
            <UseApi />
          </Typography>
          <div className="button">
            <div className="button_ext">
              <LightTooltip enterDelay={500} title="Open info box">
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleDrawerOpen}
                  className={clsx(open && classes.hide)}
                >
                  INFO
                </Button>
              </LightTooltip>
              <LightTooltip enterDelay={500} title="Report an issue or add information">
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disableElevation
                  onClick={handleFormOpen}
                >
                  Report/Add Info
                </Button>
              </LightTooltip>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <main className={clsx(classes.content, { [classes.contentShift]: open })}></main>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className="drawerHeader">
          <div className="button_int">
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <LightTooltip enterDelay={500} title="Open info box">
            <div className="lerma_etichetta">INFO</div>
          </LightTooltip>
        </div>
        <Divider />
        <div>
          <Tabs />
        </div>
      </Drawer>
      <UserFeedback
        handleFormClose={handleFormClose}
        formOpen={formOpen}
        selectedIri={currentIri}
		selectedLabel={currentLabel}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVerifying: state.auth.isVerifying,
  user: state.auth.user,
  watermarkSvg: state.graph.watermarkSvg,
  watermarkUrl: state.graph.watermarkUrl,
  criteria: state.graph.criteria,
  target: state.graph.target,
  searchResults: state.graph.searchResults,
});

const mapDispatchToProps = (dispatch) => ({
  onSearchCriteriaChanged: (value) => dispatch({ type: 'UPDATECRITERIA', criteria: value }),
  onSearchResultsChanged: (value) => dispatch({ type: 'UPDATERESULTS', results: value }),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GraphWorkspace);
