import React from "react";
import "../styles/welcome.css";

import Zoom from "react-reveal/Zoom"; // Importing Zoom effect

class Welcome extends React.Component {
  render() {
    return (
      <div className="main_welcome">
        <Zoom>

          <h1 className="title">
            World Literature Knowledge Graph
          </h1>
        </Zoom>

      </div>
    );
  }
}

export default Welcome;