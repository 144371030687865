import React from 'react';
import "../../styles/tutorial.css";

class Video extends React.Component {
render(){
   return(
      <div>
          <video 
            className="myVideo" 
            controls 
            autostart 
            autoPlay 
            src={this.props.src} 
            type={this.props.type}
          />
     </div>
      )
    }
  }

  export default Video;