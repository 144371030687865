import React from "react";
import Fade from "react-reveal/Fade";
import { Row, Col } from "react-bootstrap";
import "../styles/logos.css";

import unibaLogo from "../images/uniba.svg";
import unitoLogo from "../images/unito.png";
class Logos extends React.Component {
  render() {

    return (
      <div className="main_logos">
        <Fade>
          <div className="text">
            <p>REALIZED IN COLLABORATION WITH: </p>
          </div>
          <Row className="justify-content-md-center text-center">
            <Col xs={6} md={6} >
              <Row xs={12} md={6} >
                <a
                  className="link"
                  href="https://www.uniba.it/it/ricerca/dipartimenti/informatica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Aldo Moro University of Bari
                </a>
              </Row>
              {/* <Row xs={12} md={6} >
                <img src={unibaLogo} alt="University of Bari Aldo Mori" className="imagelogo1" />
              </Row> */}
            </Col>


            <Col xs={6} md={6} >
              <Row xs={12} md={6} >
                <a
                  className="link"
                  href="http://www.di.unito.it"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Turin
                </a>
              </Row>
              {/* <Row xs={12} md={6} >
                <img src={unitoLogo} alt="University of Turin" className="imagelogo2" />
              </Row> */}
            </Col>
          </Row>
        </Fade >
      </div >
    );
  }
}

export default Logos;
