import React, { useCallback } from 'react';
import {useEvent, useList} from 'react-use';
import '../styles/useApi.css';
import { state } from 'venti';

var temp = ''
var st = ['']
var words = ['']
var timeStamp = ['']

const KeyDown = () => {
    const [list, {push}] = useList();
    const onKeyDown = useCallback(({key}) => {
        push(key);
        try{
            timeStamp = Date.now()
            if (timeStamp != temp) { 
                temp = timeStamp
                if (key != ' ' && key != 'Backspace' && key != 'Enter' && key != 'Shift') {
                    // console.log(key)
                    st += [...key]
                    // console.log('st', st)
                }
                else if ((key === ' ' || key === 'Enter') && st != ['']) { 
                    words.push([st, Date.now()])
                    // console.log(key)
                    // VENTI login già autorizzato
                    state.set('ArcaEvent', {
                        event: st,
                        timeStamp: new Date().toLocaleString("it")
                    })
                    // console.log('words', words, Date.now())
                    st = ''
                }
            }     
        }
        catch{
            return(true)
        }
         
    }, []);
    useEvent('keydown', onKeyDown);
    return (true);
}

export default KeyDown;