import React, { useMemo } from "react";
import { connect } from "react-redux";
import queryMetadata from './query/Metadata';
import queryWork from './query/Work';
import queryWorkTopic from './query/WorkTopic';
import queryWorkPlace from './query/WorkPlace';
import querySubject from './query/Subject';
import { useQuery } from "../useQuery";
import Loading from "../../../LoadingSpinner/Loading";
import ItemListComponent from "../ItemListAuthor";

function Query(props) {
    const { target } = props;

    const [itemList, itemListLoading] = useQuery('/writers', queryWork(target.iri));
    const [itemList1, itemList1Loading] = useQuery('/writers', queryWorkTopic(target.iri));
    const [itemList2, itemList2Loading] = useQuery('/writers', queryMetadata(target.iri));
    const [itemList3, itemList3Loading] = useQuery('/writers', querySubject(target.iri));
    const [itemList4, itemList4Loading] = useQuery('/writers', queryWorkPlace(target.iri));


    const combinedList = useMemo(() => {
        return [...itemList, ...itemList1, ...itemList2, ...itemList3, ...itemList4];
    }, [itemList, itemList1, itemList2, itemList3, itemList4]);

    const uniqueList = useMemo(() => {
        return Array.from(new Set(combinedList));
    }, [combinedList]);

    if (
        itemListLoading ||
        itemList1Loading ||
        itemList2Loading ||
        itemList3Loading ||
        itemList4Loading ||

        uniqueList.length === 0
    ) {
        return <Loading />;
    } else {
        return (
            <>
                <ItemListComponent itemList={uniqueList} />
            </>
        );
    }
}


function mapStateToProps(state) {
    return {
        target: state.graph.target
    };
}

export default connect(mapStateToProps)(Query);