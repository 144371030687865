import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import '../../../styles/quizgift.css'
import SfidaStep from './sfidaStep/SfidaStep';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

export default function QuizGift() {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('Choose wisely');
  const [stepquizGift, setStepquizGift] = React.useState(0);


  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setHelperText(' ');
    setError(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (value === 'best') {
      setHelperText(<div className='textHelperX' >Meraviglioso!</div>);
      setError(false);
      setStepquizGift(2);
    } else if (value === 'worst') {
      setHelperText(<div className='textHelperX' >Ok grazie!</div>);
      setError(true);
      setStepquizGift(1);
    } else {
      setHelperText(<div className='textHelperX' >Per favore selezionare un'opzione.</div>);
      setError(true);
    }
  };

  if (stepquizGift === 0) {
    return (
        <div>
            <div className='titlChal'>
            HAI COMPLETATO IL TEST! Grazie per il tuo contributo!
            </div>
            <form className='mainQuizGift' onSubmit={handleSubmit}>
            <FormControl component="fieldset" error={error} className={classes.formControl}>
                <div className='titleQuiz'>Sei interessato a ricevere un regalo dal team Arca e ad affrontare una sfida ?</div>
                <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
                <FormControlLabel value="best" control={<Radio />} label="Sì, voglio affrontare una sfida!" />
                <FormControlLabel value="worst" control={<Radio />} label="Non per ora" />
                </RadioGroup>
                <FormHelperText>{helperText}</FormHelperText>
                <Button type="submit" variant="outlined" color="secondary" className={classes.button}>
                Controlla la risposta
                </Button>
            </FormControl>
            </form>
        </div>
      );
  }

  if (stepquizGift === 1) {
    return (
        <div>Grazie</div>
      );
  }

  if (stepquizGift === 2) {
    return (
        <div>
            <div className='startC'>Cominciamo la sfida!</div>
            <SfidaStep />
        </div>
      );
  }


  
}