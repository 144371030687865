import React from "react";
import Button from "@material-ui/core/Button";
import "../../styles/load_button.css";

const SparqlClient = require('sparql-http-client')

// Funzione per far caricare un TTL in BazeGraph
async function loadTTL() {

  const client = new SparqlClient({
    endpointUrl: 'http://localhost:9999/blazegraph/namespace/kb/sparql',
    updateUrl: 'http://localhost:9999/blazegraph/namespace/kb/sparql'
  });

  // Prendo il contenuto del file TTL (va inserito come copia incolla)
  let dati = prompt('Inserisci dati')

  // debugger;
  const stream = await client.query.update(dati)

  // console.log('click', stream)
  alert('Caricamento dati')
}

// Componente per far caricare un TTL in BazeGraph
function Load_button() {
  return (
    <div className="">
      <div className="loadButton">
        <Button onClick={loadTTL}>Carica</Button>
      </div>
    </div>
  );
}

export default Load_button;
