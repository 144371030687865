import { useVenti } from 'venti';
import React, { useState } from 'react';
import { db, arrayUnion } from '../../firebase/firebase';
import ChallangeWatermark from "./Challenge";
import ImgChallenge from '../../images/challenge.png'
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';

export default function Challange (props) {

  const [selection, setSelection] = useState([])
  const [check, setCheck] = useState([])

  const state = useVenti() // VENTI this is needed so your component updates when state changes
  const user_mail = state.get(`UserLogin.email`) // VENTI get a property
  // const items = state.get("items"); // VENTI get a property
  const LightTooltip = withStyles((theme) => ({
		tooltip: {
			backgroundColor: theme.palette.common.black,
			color: '#fff',
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
  }))(Tooltip);
  
  
  // CLOUD FIRESTORE GET DOCUMET
  if (user_mail != null && check!=true) {
    const usersRef = db.collection('challange').doc(user_mail);
    usersRef.get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) { //READ HISTORY
          usersRef.onSnapshot((doc) => { // do stuff with the data
              setSelection(docSnapshot.data().answer);
          });
        }
    });
    setCheck(true)
  } 

  // PAGE OF HISTORY
  return (
    <div>
      <ChallangeWatermark selection = {selection}/>
    </div>
  )
}
