import React, { useState, useEffect, useCallback, useRef } from "react";
import request from 'superagent'
import { connect } from "react-redux";

function queryPrizeAwarded(targetIri) {
  const queryString = `
    PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
  PREFIX owl:  <http://www.w3.org/2002/07/owl#>
  PREFIX bds: <http://www.bigdata.com/rdf/search#>
  PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
  PREFIX :<https://purl.archive.org/urwriters#>
  PREFIX urb:<https://purl.archive.org/urbooks#>
  PREFIX rdfs:<http://www.w3.org/2000/01/rdf-schema#>

  SELECT ?inst "Prize nominated" as ?class ?label ?blankType
  WHERE {
    {
      SELECT DISTINCT ?inst WHERE {
        ?inst <https://purl.archive.org/urwriters#isSettingFor> <` + targetIri + `> .
        ?inst rdf:type/rdfs:subClassOf* :Prize .
        OPTIONAL {
          ?inst <https://purl.archive.org/urwriters#isSettingFor> ?blankType .
          FILTER(?blankType = (<https://purl.archive.org/urwriters#nominated>))
        }
      }
    }
    OPTIONAL {
      ?inst rdfs:label ?label .    
    }
  }

   `;
  return queryString;
}

export default queryPrizeAwarded;
