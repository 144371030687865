import React, { useEffect, useState } from "react";
import Query from "./query_work";
import { connect } from "react-redux";

function DefaultTab(props) {
 const { target } = props;

 const [link_query, setLink_query] = useState(target.iri);

 useEffect(() => {
  if (target.iri !== link_query) {
   setLink_query(target.iri);
  }
 }, [target]);

 const QueryComponent = (
  <div className="container_box">
   <div className="associate_box">
    <Query link_query={link_query} />
   </div>
  </div>
 );

 if (target.iri === null) {
  return <div className="container_box">{/* EMPTY */}</div>;
 }
 if (target.iri != link_query) {
  return null;
 }
 if (target.iri == link_query) {
  return QueryComponent;
 }
}

function mapStateToProps(state) {
 return {
  target: state.graph.target,
 };
}

export default connect(mapStateToProps)(DefaultTab);
