import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import WritersAuthor from '../Writers/authorView/index_author'
import WritersWork from '../Writers/workView/index_work'
import MapDialogForWriter from '../Writers/MapDialogForWriter/dialogView';
import "./style.css";
import NoSelection from './NoSelection';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function ScrollableTabsButtonForce(props) {

  const {
    target } = props;

  const [value, setValue] = useState(0);

  const handleLinkClick = (event) => {
    event.preventDefault();
  };

  if (target !== undefined && target.iri !== undefined) {
    if (target.iri.includes('author')) {

      const { label: { values } } = target._data;
      const labelObj = values.find((value) => value.datatype === 'http://www.w3.org/2001/XMLSchema#string');
      const Label = labelObj ? labelObj.text : 'null';
      const StrLabel = values.filter((key) => key.datatype === 'http://www.w3.org/2001/XMLSchema#string' ? key.text[0] : '');
      const label = Label !== 'null' ? Label : JSON.stringify(StrLabel).replace('[{"text":"', '').replace('","lang":"","datatype":"http://www.w3.org/2001/XMLSchema#string"}]', '');


      return (

        <div>
          <div style={{ padding: '20px', paddingBottom: '0px', textAlign: "left", fontSize: "15px" }}>"<span className='bookR'><a className='bookR' href={target.iri}>{label}</a></span>" details. <span style={{ margin: '10px' }}><MapDialogForWriter label={label} targetIri={target.iri} /></span></div>

          <TabPanel style={{ width: "-webkit-fill-available" }} className="table_books" value={value} index={0}>
            <div className="con_boxMappa">
              <div className="boxMappa">
                <WritersAuthor link_query={target.iri} />
              </div>
            </div>
          </TabPanel>

        </div>
      )
    }

    if (target.iri.includes('_work_')) {

      const { label: { values } } = target._data;
      const labelObj = values.find((value) => value.datatype === 'http://www.w3.org/2001/XMLSchema#string');
      const Label = labelObj ? labelObj.text : 'null';
      const StrLabel = values.filter((key) => key.datatype === 'http://www.w3.org/2001/XMLSchema#string' ? key.text[0] : '');
      const label = Label !== 'null' ? Label : JSON.stringify(StrLabel).replace('[{"text":"', '').replace('","lang":"","datatype":"http://www.w3.org/2001/XMLSchema#string"}]', '');

      return (

        <div>
          <div style={{ padding: '20px', paddingBottom: '0px', textAlign: "left", fontSize: "15px" }}>Work details"<span className='bookR'><a className='bookR' href={target.iri}>{label}</a></span>".</div>

          <TabPanel style={{ width: "-webkit-fill-available" }} className="table_books" value={value} index={0}>
            <div className="con_boxMappa">
              <div className="boxMappa">
                <WritersWork link_query={target.iri} />
              </div>
            </div>
          </TabPanel>

        </div>
      )
    }

    else {
      return (
        <NoSelection handleLinkClick={handleLinkClick} />
      )
    }
  }

  else {
    return (
      <NoSelection handleLinkClick={handleLinkClick} />
    )
  }
}

function mapStateToProps(state) {
  return {
    criteria: state.graph.criteria,
    target: state.graph.target,
  };
}


export default connect(mapStateToProps)(ScrollableTabsButtonForce)