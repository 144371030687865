import React from 'react';
import { connect } from "react-redux";
import { useVenti } from 'venti';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import QuestionnaireCustomizedDialogs from './Dialog_questionnaire';
import Button from "@material-ui/core/Button";

import Load_button from './Load_button';

import '../../styles/menu_button.css';


export default function FullWidthGrid() {

	const state = useVenti() // VENTI this is needed so your component updates when state changes
  	const user_mail = state.get(`UserLogin.email`) // VENTI get a property



	return (
		<div className='lateral_right'>
			<Grid container  direction="row"  justify="center"  alignItems="center" container spacing={3}>
				{/* <Grid direction="row" style={{ minWidth: 'fit-content', marginRight: "0px" }} item xs={1} sm={1}>
					<QuestionnaireCustomizedDialogs />
					<Load_button/>
				</Grid> */}
				{/* <Grid direction="row" style={{ minWidth: 'fit-content', marginRight: "0px" }} item xs={1} sm={1}>
					<Button type="button"
						fullWidth
						variant="contained"
						color="primary"
						disableElevation
						className="button_history"
						onClick={sendData}>
							STORYBOOK
					</Button>
				</Grid> */}
			</Grid>
		</div>
	);
}
