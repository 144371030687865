import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListaCustomizedDialogs from './Dialog_lista';
import '../../styles/menu_button.css';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

export default function FullWidthGrid() {
	const classes = useStyles();

	return (
		<div className='lateral_left'>
			<Grid container  direction="column"  justify="center"  alignItems="center" container spacing={1}>
				<Grid item xs={1} sm={3}>
					<ListaCustomizedDialogs />
				</Grid>
			</Grid>
		</div>
	);
}