import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { State, state } from 'venti';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import GridSpring from '../Tutorial/index';
import legend from '../../images/legend.png';
import '../../styles/tutorial.css';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function TutorialCustomizedDialogs() {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
		//call VENTI api
		state.set('eventsButton', {
			type: 'legendButton',
			timeStamp: new Date().toLocaleString(),
		})
	};
	const handleClose = () => {
		setOpen(false);
	};

	const LightTooltipInfo = withStyles((theme) => ({
		tooltip: {
			backgroundColor: theme.palette.common.white,
			color: 'rgba(0, 0, 0, 0.87)',
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	}))(Tooltip);

	//IS THE IMAGE BROKEN?
	var images = document.querySelectorAll('img');

	var errorHandler = function () {
		this.src = 'http://195.231.2.149/erma_assets/Copertine/def_cop.jpg';
	};

	for (var i = 0; i < images.length; i++) {
		images[i].onerror = errorHandler;
	}

	return (
		<div>
			<LightTooltipInfo
				enterDelay={500}
				title="Open the legend"
				placement="right-start">
				<Button
					type="button"
					fullWidth
					variant="contained"
					color="primary"
					disableElevation
					className="button_tutorial"
					onClick={handleClickOpen}
				>
					Legend
				</Button>
			</LightTooltipInfo>

			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<DialogTitle id="customized-dialog-title">Legend of the father <a href='https://link.springer.com/article/10.1007/s00799-022-00343-0'>ARCA system</a></DialogTitle>

				<DialogContent dividers>
					<img
						className="legendIMG"
						src={legend}
						size="-webkit-fill-available"
					></img>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
