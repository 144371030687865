import React from 'react';
import { Row, Col } from "react-bootstrap";
// import VIDEOTRAILER from "../video/wlkglong.mp4";

function VideoIntro() {
 const videoIds = ['QV123ir3WbQ', 'kOSrGVqYniQ'];

 return (
  <Row className="justify-content-md-center text-center">
   <Col xs={6} md={6} >
    <iframe
     title="Video 1"
     width="100%"
     height="315"
     src={`https://www.youtube.com/embed/${videoIds[0]}`}
     frameBorder="0"
     allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
     allowFullScreen
    />
   </Col>
   <Col xs={6} md={6} >
    <iframe
     title="Video 2"
     width="100%"
     height="315"
     src={`https://www.youtube.com/embed/${videoIds[1]}`}
     frameBorder="0"
     allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
     allowFullScreen
    />
   </Col>
  </Row>
 );
}

export default VideoIntro;