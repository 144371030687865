import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import './../../styles/fab.css';
import TopButton from './TopButton';
import RightButton from './RightButton';
import LeftButton from './LeftButton';
import PathButton from './PathButton';
import HistoryButton from './HistoryButton';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuListComposition() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };


  const fab_handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root} >
      <div className={open ? 'fab_1' : 'fab'}>
        <Button
          ref = {anchorRef}
          aria-controls = {open ? 'menu-list-grow' : undefined}
          aria-haspopup = "true"
          onClick = {handleToggle}
        >
          {/* <span className='fab_label'>Info</span> */}
          <span>{open ?  <CancelIcon fontSize='large' color="disabled" /> : <InfoIcon fontSize='large' color="primary"/>}</span>

        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                {/* Per chiudere il menu cliccando fuori dal pulsante */}
                {/* <ClickAwayListener onClickAway={fab_handleClose}>  */}
                <ClickAwayListener>
                  <div id="menu-list-grow">
                    <TopButton />
                    <RightButton className='lateral_right' />
                    {/* <LeftButton className='lateral_left' /> */}
                    {/* <PathButton className='path_button' /> */}
                    <PathButton className='lateral_left' />
                    <HistoryButton className='history_button' />
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}