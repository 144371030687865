import React, { useState, useEffect, useCallback, useRef } from "react";
import request from 'superagent'
import { connect } from "react-redux";

function queryAuthor(targetIri) {
    const queryString = `
            PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
            PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX owl:  <http://www.w3.org/2002/07/owl#>
            PREFIX bds: <http://www.bigdata.com/rdf/search#>
            PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
            PREFIX :<https://purl.archive.org/urwriters#>
            PREFIX urb:<https://purl.archive.org/urbooks#>
            PREFIX rdfs:<http://www.w3.org/2000/01/rdf-schema#>

            SELECT ?inst ?class ?label ?blankType 
            WHERE {
                {
                    SELECT DISTINCT ?inst  WHERE {
                        
                        { <` + targetIri + `> <https://purl.archive.org/urbooks#hasSubject> ?inst . FILTER(isIri(?inst)) }
                        
                        
                        
                    }
                    
                    LIMIT 100 OFFSET 0
                }
                OPTIONAL {?inst rdf:type ?foundClass}
                    BIND (coalesce(?foundClass, owl:Thing) as ?class)
                    OPTIONAL {?inst rdfs:label ?label}
                
            } 
    `;
    return queryString;
}

export default queryAuthor;
