import React, { useState, useEffect, useCallback, useRef } from "react";
import request from 'superagent'
import { connect } from "react-redux";

function queryWorkPlace(targetIri) {
    const queryString = `
        PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX :<https://purl.archive.org/urwriters#>
        PREFIX urb:<https://purl.archive.org/urbooks#>
        PREFIX rdfs:<http://www.w3.org/2000/01/rdf-schema#>
        PREFIX geo:<http://www.w3.org/2003/01/geo/wgs84_pos#>

        SELECT DISTINCT ?inst ?class ?label ?lat ?lng ?blankType
        WHERE {
            {
                SELECT DISTINCT ?inst  WHERE {
                    {
                        ?work <https://purl.archive.org/urwriters#wasAttributedTo> <` + targetIri + `> .
                        ?work :wasPublishedWhere ?inst . 
                    FILTER(isIri(?inst)) }
                }
                
            }
        OPTIONAL {?inst rdf:type ?foundClass}
        BIND (coalesce(?foundClass, owl:Thing) as ?class)
        OPTIONAL {
            ?inst rdfs:label ?label .
            ?inst geo:lat ?lat .
            ?inst geo:long ?lng .
        }

    
        } 
  `;
    return queryString;
}

export default queryWorkPlace;
