import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { db, arrayUnion } from '../../firebase/firebase';
import Tooltip from '@material-ui/core/Tooltip';
import '../../styles/questionario.css';
import { state } from 'venti';
// import Draggable from './Draggable';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AlertDialog from './questionnaire/AlertDialogCompleted';
import RedeemIcon from '@material-ui/icons/Redeem';


export default function QuestionnaireCustomizedDialogs() {
	// const [open, setOpen] = React.useState(false);
	const [completed1, setCompleted1] = React.useState(false);
	const [completed2, setCompleted2] = React.useState(false);
	const [completed3, setCompleted3] = React.useState(false);

	const [challenger, setChallanger] = React.useState(false);
	const user_mail = state.get(`UserLogin.email`) // VENTI get a property
	const [openComplete, setOpenComplete] = React.useState(false);


	const openTest = (e) => {
		e.preventDefault();
		const url = '/test';
		// window.open(url);
		window.location.replace(url)
		
		//call VENTI api
		state.set('eventsButton',{
			type: 'questionButton',
			timeStamp: new Date().toLocaleString(),
		})

		}

	const LightTooltipInfo = withStyles((theme) => ({
		tooltip: {
			backgroundColor: theme.palette.common.white,
			color: 'rgba(0, 0, 0, 0.87)',
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	}))(Tooltip);

	// CLOUD FIRESTORE GET DOCUMET questARCAcomparative
	if (user_mail != null) {
		const usersRef = db.collection('questARCAcomparative').doc(user_mail);
	  usersRef.get()
	    .then((docSnapshot) => {
	      if (docSnapshot.exists) {
	        usersRef.onSnapshot((doc) => { 
				setCompleted1(docSnapshot.data().completed);
	        });
		  }
		  else {
			setCompleted1(false);
		  }
	  });
	} 
	// CLOUD FIRESTORE GET DOCUMET questARCA
	if (user_mail != null) {
		const usersRef = db.collection('questARCA').doc(user_mail);
	  usersRef.get()
	    .then((docSnapshot) => {
	      if (docSnapshot.exists) {
	        usersRef.onSnapshot((doc) => { 
				setCompleted2(docSnapshot.data().completed);
	        });
		  }
		  else {
			setCompleted2(false);
		  }
	  });
	} 

	// CLOUD FIRESTORE GET DOCUMET questARCAopen
	if (user_mail != null) {
		const usersRef = db.collection('questARCAopen').doc(user_mail);
	  usersRef.get()
	    .then((docSnapshot) => {
	      if (docSnapshot.exists) {
	        usersRef.onSnapshot((doc) => { 
				setCompleted3(docSnapshot.data().completed);
	        });
		  }
		  else {
			setCompleted3(false);
		  }
	  });
	} 

	// CLOUD FIRESTORE GET DOCUMET Challange
	if (user_mail != null) {
		const usersRef = db.collection('challange').doc(user_mail);
	  usersRef.get()
	    .then((docSnapshot) => {
	      if (docSnapshot.exists) {
	        usersRef.onSnapshot((doc) => { 
				setChallanger(docSnapshot.data().answer.try);
	        });
		  }
		  else {
			setChallanger(false);
		  }
	  });
	} 

	const completedQuestionnaire = () => {
		setOpenComplete((prevOpenComplete) => !prevOpenComplete);
	  }; 
	
	const checkClick = () => {
		
		//call VENTI api
		state.set('eventsButton',{
			type: 'giftButton',
			timeStamp: new Date().toLocaleString(),
		})
		
	}; 
	

	if (completed1 === false || completed2 === false || completed3 === false) {
		return (
			<div>
				<LightTooltipInfo enterDelay={500} title="Go to the questions">
					<Button
						type="button"
						fullWidth
						variant="contained"
						color="primary"
						disableElevation
						className="button_questionnaire"
						onClick={openTest}
					>
						<QuestionAnswerIcon/>						
					</Button>
				</LightTooltipInfo>

			</div>
		);
	}


	else if (completed1 === true && completed2 === true && completed3 === true && challenger == false) {
		return (
			<div>
				<div>
					{openComplete ? 
							(
								<AlertDialog/>
							) :  (
								<span>
									
								</span>
							)
					}
				</div>
				<LightTooltipInfo enterDelay={500} title="Go to the questions">
					<Button
						type="button"
						fullWidth
						variant="contained"
						color="primary"
						disableElevation
						className="button_questionnaire"
						onClick={completedQuestionnaire}
					>
						{openComplete ? 
							(
								<span>
									<QuestionAnswerIcon/>
								</span>
							) :  (
								<span>
									<QuestionAnswerIcon/>
								</span>
							)
						}
					</Button>
				</LightTooltipInfo>
			</div>
		);
	}

	else if (completed1 === true && completed2 === true && completed3 === true && challenger == true) {
		return(
				<LightTooltipInfo enterDelay={500} title="Completa la SFIDA. 
				Fai compilare il questionario ai tuoi due amici">
					<button
						type="button"
						fullWidth
						variant="contained"
						color="secondary"
						disableElevation
						className="button_GiftQuestionnaire"
						onClicl={checkClick}
					>
						<span style={{position: 'relative', top: '-2px'}}><RedeemIcon /></span>
					</button>
				</LightTooltipInfo>
		)
	}
}