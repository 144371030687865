import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card } from "react-bootstrap";
import './style.css'

const classTextMap = {
  'http://www.w3.org/2002/07/owl#Class': 'Type',
  'https://purl.archive.org/urwriters#InformationObject': 'WIKI Link',
  'https://purl.archive.org/urbooks#Folksonomy': 'Subject',
  'https://purl.archive.org/urwriters#Country': 'Citizenship',
  'https://purl.archive.org/urwriters#Language': 'Language',
  'https://purl.archive.org/urbooks#WebManifestation': 'Source',
  'https://purl.archive.org/urwriters#Person': 'Author',
  'https://purl.archive.org/urwriters#Gender': 'Gender',
  'http://www.w3.org/2002/07/owl#NamedIndividual': 'Named Individual',
  'https://purl.archive.org/urbooks#Expression': 'Work',
  'https://purl.archive.org/urwriters#Place': 'Work publishing place',
  'https://purl.archive.org/urwriters#TimeInterval': 'Years lived',
  'https://purl.archive.org/urwriters#Role': 'Role',
  'https://purl.archive.org/urwriters#Publisher': 'Publisher',
  'https://purl.archive.org/urwriters#Prize': 'Prize',
  'https://purl.archive.org/urwriters#Minority': 'Belongs to minority',
  'https://purl.archive.org/urbooks#ReadersCount': 'Number of readers',
  'https://purl.archive.org/urbooks#RatingCount': 'Number of reatings',
  'https://purl.archive.org/urbooks#Rating': 'Rating',

};

const classStyletMap = {
  'https://purl.archive.org/urwriters#Person': '#E3B600',
  'https://purl.archive.org/urbooks#Expression': '#92d827',
  'https://purl.archive.org/urwriters#Role': '#507cff',
  // 'https://purl.archive.org/urwriters#Prize': '#fbe900',
  'https://purl.archive.org/urwriters#Minority': '#990987',
  'Prize awarded': '#fbe900',
};

function getClassText(value) {
  // console.log(value, classStyletMap[value])
  return classTextMap[value] || value;
}
function getClassStyle(value) {
  // console.log(value, classStyletMap[value])
  return classStyletMap[value] || '#A9A9A9';

}

function ItemListComponent({ itemList }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);

  const groupByClass = itemList.reduce((groups, item) => {
    const { class: { value: classValue } = {}, label: { value: labelValue } = {}, inst: { value: instValue } } = item;
    if (!groups[classValue]) {
      groups[classValue] = {
        classValue,
        labels: new Set(),
        items: [],
      };
    }
    groups[classValue].labels.add(labelValue || instValue.split("#")[1]);
    groups[classValue].items.push(item);
    return groups;
  }, {});

  const groups = Object.values(groupByClass).sort((a, b) => b.classValue?.localeCompare(a.classValue));

  const filteredGroups = useMemo(() => {
    if (searchTerm.length === 0) {
      return groups;
    }

    return groups.map((group) => {
      const filteredLabels = [...group.labels].filter((label) =>
        label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return { ...group, labels: new Set(filteredLabels) };
    }).filter((group) => group.labels.size > 0);
  }, [groups, searchTerm]);

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/plain", "This text may be dragged");
    event.target.classList.add("dragging");
  };

  const handleDragEnd = (event) => {
    event.target.classList.remove("dragging");
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const workGroup = groups.find((group) => group.classValue === "https://purl.archive.org/urbooks#Expression");
    setShowSearchBar(workGroup && workGroup.items.length > 7);
  })

  return (
    <Row md={5}>
      {showSearchBar && (
        <Row>
          <Col sm={{ span: 12, offset: 0 }} className="searchColRow">
            <input
              type="text"
              placeholder="Search details..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Col>
        </Row>
      )}
      {filteredGroups.map((group, index) => (
        <React.Fragment key={index}>
          <Row className="rowC">
            <Col sm={6}>
              <a
                draggable
                onClick={handleLinkClick}
                className="a_titleClass"
                href={group.classValue}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                {getClassText(group.classValue)}
              </a>
            </Col>
            <Col sm={6}></Col>
          </Row>
          {[...group.labels].map((label, index) => (
            <Row className="rowStyle" key={index}>
              <Col sm={{ span: 12, offset: 0 }} className="whiteColRow">
                <a
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  className="a_imagelink"
                  style={{
                    borderColor: getClassStyle(group.classValue),
                    borderLeft: `9px solid` + getClassStyle(group.classValue),
                  }}
                  onClick={handleLinkClick}
                  href={itemList.find(
                    (item) =>
                      (item.label ? item.label.value : item.inst.value.split("#")[1]) === label
                  ).inst.value}
                >
                  {label}
                </a>
              </Col>
            </Row>
          ))}
        </React.Fragment>
      ))}
    </Row>
  );
}


ItemListComponent.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.shape({
    class: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    inst: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    label: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  })).isRequired,
};

export default ItemListComponent;

