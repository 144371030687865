import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import request from 'superagent';

export function useQuery(endpoint, query) {
 const [result, setResult] = useState([]);
 const [loading, setLoading] = useState(false);

 useEffect(() => {
  async function fetchData() {
   try {
    const response = await request
     .get(endpoint)
     .accept("application/json")
     .query({ query })
     .buffer(true);

    if (!response.text) {
     throw new Error('Response text is empty');
    }
    const results = JSON.parse(response.text).results.bindings;
    setResult(prevResults => {
     const uniqueResults = [];
     results.forEach(result => {
      uniqueResults.push(result);
     });
     const mergedResults = Array.from(new Set([...prevResults, ...uniqueResults])).map(obj => {
      try {
       return JSON.parse(JSON.stringify(obj));
      } catch (error) {
       console.error(`Failed to serialize result: ${error.message}`);
       return null;
      }
     }).filter(obj => obj !== null);

     return mergedResults;
    });
    setLoading(false);
   } catch (error) {
    setLoading(false);
    console.error(error);
    // throw new Error(`Failed to fetch data from ${endpoint}`);
   }
  }

  fetchData();
 }, [endpoint, query]);

 return [result, loading];
}

