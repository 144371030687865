import React, { useMemo } from "react";
import { connect } from "react-redux";
import queryAuthor from './query/Author';
import queryDetails from './query/Details';
import queryPublisher from './query/Publisher';
import queryPublishWhere from './query/PublishWhere';
import querySubject from './query/Subject';
import queryPrizeAwarded from './query/PrizeAwarded';
import queryPrizeNominated from './query/PrizeNominated';
import Loading from "../../../LoadingSpinner/Loading";
import ItemListComponent from "../ItemListWork";
import { useQuery } from "../useQuery";

function Query(props) {
    const { target } = props;

    const [itemList, itemListLoading] = useQuery('/writers', queryAuthor(target.iri));
    const [itemList1, itemList1Loading] = useQuery('/writers', queryPublishWhere(target.iri));
    const [itemList2, itemList2Loading] = useQuery('/writers', queryPublisher(target.iri));
    const [itemList3, itemList3Loading] = useQuery('/writers', queryDetails(target.iri));
    const [itemList4, itemList4Loading] = useQuery('/writers', querySubject(target.iri));
    const [itemList5, itemList5Loading] = useQuery('/writers', queryPrizeAwarded(target.iri));
    const [itemList6, itemList6Loading] = useQuery('/writers', queryPrizeNominated(target.iri));

    const combinedList = useMemo(() => {
        return [...itemList, ...itemList1, ...itemList2, ...itemList3, ...itemList4, ...itemList5, ...itemList6];
    }, [itemList, itemList1, itemList2, itemList3, itemList4, itemList5, itemList6]);

    const uniqueList = useMemo(() => {
        return Array.from(new Set(combinedList));
    }, [combinedList]);

    if (
        itemListLoading ||
        itemList1Loading ||
        itemList2Loading ||
        itemList3Loading ||
        itemList4Loading ||
        itemList5Loading ||
        itemList6Loading ||
        uniqueList.length === 0
    ) {
        return <Loading />;
    } else {
        return (
            <>
                <ItemListComponent itemList={uniqueList} />
            </>
        );
    }
}


function mapStateToProps(state) {
    return {
        target: state.graph.target
    };
}

export default connect(mapStateToProps)(Query);